import React from 'react';
import { months } from '../../../../../../../utils/constants';

export default function History({ viewIsActive , drugs , clientId } ) {

  const Row = ({ drugStock , index  , type , count }) => {
    
    const { history , currentDate } = drugStock;

    var date = new Date(currentDate);

    var stock = [];
    
    for (const property in history) {
      let number = property.replace('Month ','')
      if (parseFloat(number) <= 0 && property !== 'Month 0 Contingentement') {
        let i = Math.abs(parseInt(number))
        let month = date.getMonth() - i;
        let label = <span>{months[month]}<br/>{date.getFullYear()}</span>
        if(month < 0) {
          month = 12 - Math.abs(month)
          label = label = <span>{months[month]}<br/>{date.getFullYear() -1 }</span>
        }
        stock.push({
          month: label,
          value: history[property]
        });
      }
    }

    return (
      <>
        {type === "value" ?
          <div className={`list-row${count > 1 && index !== count-1 ? " border" : ""}`}>
            <p className="bold">{drugStock.drug.name} {drugStock.packs.length > 0 && <span className="lots">{drugStock.packs.length} lots</span>}</p>
            {stock && stock.map((item , i) => <p key={'history-value' + i} className="month">{item.value}</p>)}
          </div>
          :
          <div className={"list-row labels"}>
            <p>Nom du médicament</p>
            {stock.map((item , i) => <p key={'history-month' + i} className="month">{item.month}</p>)}
          </div>
        }
      </>
    )
   }

   return (
     <>
       {viewIsActive &&
         <> 
           <Row index={drugs.length + 1} drugStock={drugs[0]} type={"label"}/>
           {drugs.map((item , i) => <Row key={'history'+ clientId + i} index={i} drugStock={item}  count={drugs.length} type={"value"}/>)}
         </>
       }
     </>
   )
}
