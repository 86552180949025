import React , { useEffect , useCallback , useState , useMemo } from 'react';
import { CgChevronDown , CgChevronUp } from 'react-icons/cg'

import { useGlobalContext } from '../../../../context/GlobalContext';
import { getStockListAction } from '../../../../actions/stockActions';

import ClientRow from './ClientRow/ClientRow';
import './ExpirationPage.scss';

export default function ExpirationPage({ setActivePage }) {

  const [ context , dispatch ] = useGlobalContext();
  const [ clientsFiltred , setClientFiltred ] = useState();
  const [ renderList , setRenderList ] = useState(false);

  const initSortState = {
    name: true,
    dept: true,
    city: true,
    drugs: true,
    stock: true,
    comments: true,
  }

  const [ sortByReverse , setSortByReverse] = useState(initSortState); 

  const { filters } = context.stock

  const getStocksList = useCallback(() => {
    getStockListAction(dispatch);
  },[dispatch])

  useEffect(() => {
    setActivePage("expirations");
    getStocksList();
    // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    if (context.stock.expirationsList) {
      setClientFiltred(context.stock.expirationsList);
    }
  },[context.stock.expirationsList]);

  useEffect(() => {
    if (filters && filters.search) {
      let filtredSearch = context.stock.expirationsList.filter(client => {
        if (client.profile.structure.toLowerCase().includes(filters.search.toLowerCase())){
          return client;
        } else if (client.code.toLowerCase().includes(filters.search.toLowerCase())){
          return client;
        } else {
          return null;
        }
      })
      setClientFiltred(filtredSearch);
    } else {
      setClientFiltred(context.stock.expirationsList);
    }
    // eslint-disable-next-line
  }, [filters]);

  function sortClients(type , isReverse ) {
    let list = clientsFiltred;
    switch (type) {

      case 'name':
        list = list.sort((a , b ) => {
          if (a.profile.structure < b.profile.structure) return -1;
          if (a.profile.structure > b.profile.structure) return 1;
          return 0
        });
        setClientFiltred(isReverse ? list.reverse() : list )
        setSortByReverse({...initSortState , name: isReverse })
        break;

      case 'dept':
        list = list.sort((a , b ) => {
          if (a.profile.dept < b.profile.dept) return -1;
          if (a.profile.dept > b.profile.dept) return 1;
          return 0
        });
        setClientFiltred(isReverse ? list.reverse() : list )
        setSortByReverse({...initSortState , dept: isReverse })
        break;

      case 'city':
        list = list.sort((a , b ) => {
          if (a.profile.city < b.profile.city) return -1;
          if (a.profile.city > b.profile.city) return 1;
          return 0
        });
        setClientFiltred(isReverse ? list.reverse() : list )
        setSortByReverse({...initSortState , city: isReverse })
        break;

      case 'drugs':
        list = list.sort((a , b ) => {
          if (a.drugs.length < b.drugs.length) return -1;
          if (a.drugs.length > b.drugs.length) return 1;
          return 0
        });
        setClientFiltred(isReverse ? list.reverse() : list )
        setSortByReverse({...initSortState , drugs: isReverse })
        break;
      
      case 'comments':
        list = list.sort((a , b ) => {
          if (a.totalComments < b.totalComments) return -1;
          if (a.totalComments > b.totalComments) return 1;
          return 0
        });
        setClientFiltred(isReverse ? list.reverse() : list )
        setSortByReverse({...initSortState , comments: isReverse })
        break;
      
      default:
        break;
    }
    setRenderList(!renderList);
  }



  const ClientList = () => {
    return clientsFiltred?.map((client , i) => {
      return <ClientRow key={'client-row' + client._id } client={client} index={i}/>
    })
  }

  const Label = ({ name , action , state , className  }) => {
    return (
      <button onClick={() => action()} className={className}>
        <p>{name}</p> 
        <div className="icon">
          {state ?  <CgChevronUp size={23} color={'#999eb0'}/> : <CgChevronDown size={23} color={'#999eb0'}/>}
        </div> 
      </button>
    )
  }

  return useMemo(() => {
    return (
      <>
        <div className="page-container list-page">
          <div className="main-row labels">
            <Label name={"Établissement"} action={() => sortClients('name' , !sortByReverse.name )} state={sortByReverse.name} className={"main-col structure"}/>
            <Label name={"Code"} action={() => sortClients('code' , !sortByReverse.dept )} state={sortByReverse.dept} className={"main-col"}/>
            <Label name={"Département"} action={() => sortClients('dept' , !sortByReverse.dept )} state={sortByReverse.dept} className={"main-col"}/>
            <Label name={"Ville"} action={() => sortClients('city' , !sortByReverse.city )} state={sortByReverse.city} className={"main-col"}/>
            <Label name={"Médicaments référencés"} action={() => sortClients('drugs' , !sortByReverse.drugs )} state={sortByReverse.drugs}  className={"main-col drugs"}/>
            {/* <Label name={"Commentaires"} action={() => sortClients('comments' , !sortByReverse.comments )} state={sortByReverse.comments}  className={"main-col"} /> */}
          </div>

          <div className="list">
            {clientsFiltred && <ClientList />}
          </div>
        </div>
      </>
    // eslint-disable-next-line
  )},[clientsFiltred , renderList ])
}
