import React from 'react';
import { Link, useHistory , useParams } from 'react-router-dom';
import { FiLogOut } from "react-icons/fi";

import './Nav.scss';
import { useGlobalContext } from '../../../context/GlobalContext';
import Filters from '../Filters/Filters';
import logo from '../../../assets/images/logo.svg';

export default function NavPharmarcy({ activePage }) {

  const [ context , dispatch ] = useGlobalContext();

  const pharmacist = context.stock.pharmacist;
  
  const history = useHistory();
  const { pharmacyId } = useParams();

  function logout() {
    history.push("/");
    localStorage.removeItem('token');
    dispatch({
      type : 'IS_AUTH',
      payload : false
    });
    dispatch({
      type : 'USER_AUTH',
      payload : null
    });
  };

  return (
    <>
    <nav className={`${activePage === "login" ? "" : "isActive"}`}>
      <div className="content">

        <div className="pharamacy">
          <div className="logo">
            <img src={logo} alt=""/>
          </div>
          <h1 className="bold name">{pharmacist && pharmacist.profile && pharmacist.profile.structure}</h1>
          <div className="links pharmacy">
            <Link to={`/pharmacy/stocks/${pharmacyId}`} className={`${activePage === "stocks" ? "isActive" : ""}`} >Contingentement</Link>
            <Link to={`/pharmacy/expirations/${pharmacyId}`} className={`${activePage === "expirations" ? "isActive" : ""}`}>Péremptions</Link>
          </div>


        </div>


        <Filters isDisabled={activePage === "login"}/>
        
        <div className="icons">

          <button className="logout" onClick={() => logout()}>
            <FiLogOut size={22} color={"#192243"}/>
          </button>

        </div>

      </div>

    </nav>
    </>
  )
}
