import React from 'react';
import { Link } from  'react-router-dom';
import { format } from 'date-fns';

import { cguDate } from '../../../utils/constants';

import styles from './Footer.module.scss';

export default function Footer() {
  return (
    <div className={styles.container}>
      <Link to="/legal" className={styles.link}>Mentions légales</Link>
      <Link to="/data-policy" className={styles.link}>Politique de confidentialités</Link>
      <Link to="/transparency" className={styles.link}>Transparence des liens d’intérêts</Link>
      <Link to="/cgu" className={styles.link}>Conditions générales d'utilisation</Link>
      <p className={styles.link}>{format(cguDate , "dd/MM/yyyy")}</p>
    </div>
  )
}
