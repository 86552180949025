import React from 'react';
import { Link } from 'react-router-dom';
import { BsArrowLeftShort } from "react-icons/bs";

import styles from './Legal.module.scss';

export default function Legal() {

  return (
    <div className={`${styles.container}`}>
      <Link to="/">
        <div className={styles.back}>
          <BsArrowLeftShort size={24} />
          Retour
        </div>
      </Link>
      <div className={styles.content}>
        <h1>Mentions légales</h1>

        <p>
          Ce site web a été créé et est contrôlé par Octapharma France, société par actions simplifiée au capital de 100 000 euros dont le siège social est situé au 62 bis avenue André Morizet, 92100 Boulogne-Billancourt, immatriculée au Registre du Commerce et des Sociétés de Nanterre sous le numéro B 451 327 308. Le numéro de téléphone est le 01.41.31.80.00.
        </p>

        <p>
          Octapharma est représentée par ses représentants légaux selon le registre du commerce (K-bis).
        </p>
        
        <p>
          La directrice de la publication est Marie-Christine BORRELLY, Directrice générale.
        </p>

        <p>
          L'hébergeur du site est Microsoft Corporation - One Microsoft Way - Redmond, WA 98052-6399 -USA
        </p>

        <p>
          En utilisant ce site web, vous vous engagez à respecter les conditions générales exposées ci-dessous ainsi que toutes les lois applicables. Si vous n’acceptez pas ces conditions générales, merci de ne pas utiliser ce site web.
        </p>

        <h3>Adhésion aux conditions générales d’utilisation</h3>

        <p>
          En vous connectant au site octapharma.fr, en ce compris les éventuels sous-sites et versions mobiles utilisant son interface et ses fonctions (ci-après ensemble le « Site »), vous acceptez sans restriction ni réserve de vous soumettre aux présentes Conditions Générales d’Utilisation (« CGU »). Veuillez lire attentivement les CGU suivantes qui s’appliquent à chaque utilisateur du Site (« Utilisateur »).
        </p>
        
        <p>
          Le présent Site a pour objectif de faire une présentation institutionnelle d’Octapharma France SAS et du Groupe Octapharma. Les CGU applicables sont celles qui sont accessibles sur le Site au jour de votre connexion sur celui-ci. Octapharma France SAS se réserve la possibilité de modifier à tout moment tout ou partie des CGU. Nous vous conseillons de les consulter régulièrement.
        </p>

        <p>
          Tout accès et/ou utilisation du Site vaut acceptation et engagement au respect de l’ensemble des termes des CGU applicables, sans restriction ni réserve.
        </p>

  
        <p>
          Ainsi, en poursuivant votre navigation sur le Site, vous acceptez sans réserve de respecter l’ensemble des CGU applicables et de vous soumettre à la loi française.
        </p>

        <p>
          Si vous ne souhaitez pas être liés par les CGU, merci de ne pas utiliser ce site web.
        </p>

        <p>
          Octapharma France SAS se réserve le droit de refuser temporairement ou définitivement l’accès au Site ou d’exclure, unilatéralement, discrétionnairement et sans notification préalable, tout Utilisateur qui ne respecterait pas les CGU, ou en cas de revendications ou plaintes émanant d’un tiers, sans aucune contrepartie, ni préavis.
        </p>

        <h3>Informations médicales – mise en garde</h3>

        <p>
          Les informations sur les produits (les Produits) présentes sur le Site sont fournies par Octapharma France SAS uniquement à titre informatif. Elles n’ont pas vocation a être complètes ou exhaustives, ni à se substituer à l’avis de votre médecin traitant ou d’un professionnel de santé. Ces informations ne peuvent servir à établir un diagnostic ni à prescrire ou utiliser des médicaments à usage humain ou des dispositifs médicaux.
        </p>

        <p>
          Les Produits énumérés ne sont disponibles que sur ordonnance délivrée par un médecin ou un professionnel de santé qualifié.
        </p>

        <p>
          Le site peut contenir des références à des Produits qui sont indisponibles ou ne sont pas commercialisés dans certains pays ou certaines régions ou qui peuvent être fournis sous un nom différent.
        </p>

        <p>
          Afin d’obtenir des informations complètes et exhaustives sur vos médicaments sur ordonnance (en ce compris leurs applications médicales bénéfiques et leurs possibles effets négatifs), vous devez consulter votre médecin traitant ou, dans certain cas, un professionnel de santé.
        </p>

        <p>
          Les professionnels de santé peuvent trouver les informations médicales appropriées sur chaque produit dans la brochure d’information correspondante.
        </p>

        <p>
          Il est rappelé que d’un pays l’autre, les informations relatives à un produit peuvent varier. Les médecins, les autres professionnels de santé et les patients sont donc invités à vérifier les informations médicales relatives à leurs pays respectifs auprès des filiales locales ou auprès des autorités de régulation.
        </p>
        
        <p>
          Enfin, le présent site n’est pas destiné à être utilisé pour signaler des problèmes de santé, des effets indésirables ni pour déposer des réclamations techniques concernant les produits du groupe Octapharma.
        </p>

        <p>
          Les informations relatives aux produits figurant sur ce site sont limitées. Pour obtenir des informations médicales complètes sur nos médicaments, veuillez :
        </p>

        <p>
          <b>Si vous êtes patient</b> : consulter <b>votre médecin prescripteur</b>
        </p>

        <p>
          Si vous êtes professionnel de santé : contacter votre interlocuteur Octapharma habituel ou nous transmettre une demande d’information médicale au 01 41 31 80 00 ou par mail 
          à <a href="mailto:FR2Infomed@octapharma.com" >FR2Infomed@octapharma.com</a>
        </p>

        <h3>Propriété intellectuelle sur le site</h3>

        <p>
          Le Site et son contenu sont protégés par le droit d’auteur. Le Site comprend des contenus qui sont la propriété d’Octapharma AG, d’Octapharma France SAS ou de tiers qui ont dûment autorisé leur utilisation ou reproduction. Le Site est mis en ligne et exploité par Octapharma France SAS.
        </p>

        <p>
          Sans autorisation expresse écrite d’ Octapharma AG et d’Octapharma France SAS, et le cas échéant de tiers titulaires de droits d’auteur, il est interdit de se livrer à une reproduction, utilisation ou copie du site, de sa structure générale, de son ergonomie, des textes, logos, noms, designs, informations relatives aux Produits ou tout autre contenu/ information publiée sur le Site ou autre (ci-après le Contenu).
        </p>

        <p>
          Aucun Contenu du site ne peut être copié , reproduit, altéré, modifié, téléchargé, dénaturé, transmis ou diffusé de quelque manière que ce soit, sur ce quel que support que ce soit , en tout ou partie, sans l’accord préalable écrit d’Octapharma France SAS et d’Octapharma AG ou, le cas échéant, des autres détenteurs de droit.
        </p>

        <p>
          L’utilisation commerciale des Contenus publiés sur le Site ainsi que leur représentation ou intégration dans un autre site web, notamment via un lien hypertexte, sont strictement interdites sauf autorisation préalable écrite donnée par Octapharma AG et d’Octapharma France SAS.
        </p>

        <p>
          Octapharma France SAS et Octapharma AG se réservent la possibilité d’engager des poursuites pour toute violation de leurs droits de propriété intellectuelle et notamment pour contrefaçon.
        </p>

        <h3>Droit de propriété intellectuelle sur les produits / marques</h3>

        <p>
          Sauf indication contraire, OCTAPHARMA AG ou ses affiliées détiennent tous les droits de propriété intellectuelle relatifs à tous les Produits énumérés sur le site. Les marques citées sur le Site sont des marques déposées et sont la propriété d’Octapharma AG ou de ses affiliées.
        </p>

        <h3>Responsabilité – exclusion et limitation</h3>

        <p>
          Les informations sont fournies « telles quelles » sans aucune garantie de quelconque nature explicite ou implicite.
        </p>

        <p>
          Dans la limite des dispositions légales, Octapharma France décline expressément toutes les responsabilités et garanties qu’elles soient explicite ou implicite en relation avec l’utilisation et l’accès au Site ou aux Produits énumérés, et notamment les responsabilités ou garantie de toute nature en rapport avec :
        </p>

        <ul>
          <li>L’exactitude ou l’exhaustivité de toute information contenue dans ce site ;</li>
          <li>L’exactitude ou l’exhaustivité de toute information contenue dans l’une des pages auxquels renvoie ce site ;</li>
          <li>Les effets thérapeutiques ou autres de l’un des produits fabriqués, fournis ou distribués par Octapharma AG et /ou par ses affiliées ;</li>
          <li>Et/ ou tout résultat d’efficacité de l’un des Produits décrits dans ce site et fabriqués, fournis ou distribués par Octapharma AG et/ou l’une de ses affiliées.</li>
        </ul>

        <p>
          Les informations sont fournies à titre indicatif, en partant du principe que tous les visiteurs du Site prennent la peine de vérifier l’exactitude et ne s’y fient qu’à leur risque et péril. L’Utilisateur du Site reconnaît utiliser ces informations sous sa responsabilité exclusive.
        </p>

        <p>
          Octapharma France SAS (y compris ses directeurs, filiales, officiers, employés agents, entrepreneurs, successeurs et ayant droits) ne peut être tenue responsable des conséquences de l’utilisation de ces informations.
        </p>

        <p>
          Octapharma France SAS (y compris ses directeurs, filiales, officiers, employés agents, entrepreneurs, successeurs et ayant droits) ne peut être tenu responsable pour les pertes et dommages causés par une quelconque personne qui s’est fiée aux informations présentes sur le Site ou celles qui y sont reprises.
        </p>

        <p>
          Il est notamment précisé que le Site peut présenter des opinions d’experts consultés dans un domaine particulier ou des articles de presse. Octapharma France décline toute responsabilité quant à l’exactitude et l’exhaustivité des informations communiquées ou relayées par ces experts ou dans ces articles. Les opinions exprimées par les experts/ auteurs d’articles n’expriment que leur opinion personnelle.
        </p>

        <p>
          Octapharma France SAS (y compris ses directeurs, filiales, officiers, employés agents, entrepreneurs, successeurs et ayant droits) ne peut en aucun cas être tenue responsable des dommages, directs ou indirects, pour toute raison, de toute origine, nature ou ayant toute conséquence quelconque, même si Octapharma avait été averti d’un tel dommage ou perte causé :
        </p>

        <ul>
          <li>En raison de l’accès au Site ou de l’impossibilité d’y accéder ;</li>
          <li>En raison de l’utilisation du Site, y compris tout dommage ou virus qui peut infecter l’équipement informatique de l’Utilisateur ou tout autre bien</li>
          <li>Et/ ou en raison de la foi apportée à toute information provenant directement ou indirectement du site.</li>
        </ul>

        <p>
          Ces exclusions de responsabilité portent notamment sur tout dommage punitifs, fortuits, spéciaux et conséquents ainsi que de toute autre dommage, y compris et sans limitation les dommages pour perte d’usage, de données, de rentabilité ou d’opportunité, engendré par ou liée d’une quelconque manière à l’utilisation ou aux performances de ce site web.
        </p>

        <h3>Code de bonne conduite</h3>

        <p>
          Octapharma France s’efforce d’appliquer le Code de Bonnes Pratiques de l’IFPMA ; sa dernière version est disponible sur 
          : <a href="https://www.ifpma.org/resource-centre/ifpma-code-of-practice/" rel="noreferrer" target="_blank">https://www.ifpma.org/resource-centre/ifpma-code-of-practice/</a>
        </p>

        <h3>Site web de tierce partie – liens hypertexte</h3>

        <p>
          À toutes fins utiles, Octapharma France SAS met sur ce Site des liens vers des sites édités et / ou exploités d’autres entités (liens hypertexte). Si vous cliquez sur ces liens, vous quitterez ce Site. Cet accès est fait à vos risques et périls et il vous incombe de prendre toutes les mesures nécessaires pour vous protéger contre les virus informatiques et les autres éléments nuisibles.
        </p>

        <p>
          Octapharma France SAS (y compris ses directeurs, filiales, officiers, employés, agents, entrepreneurs, successeurs et ayant droit) n’endosse aucune responsabilité et ne donne aucune garantie pour les sites dont un lien figure sur le Site, les informations qui y figurent ou les produits ou services qui y sont décrits ou les éventuelles publicités qui y figurent.
        </p>

        <p>
          La présence de liens ne signifie en aucun cas une affiliation ou association avec Octapharma France SAS; elle ne donne aucune autorité légale à Octapharma France SAS quant à l’utilisation de marques, noms commerciaux, logos ou le symbole copyright figurant sur ou accessible à travers les liens ; elle n’autorise pas, non plus, les sites avec des liens vers le Site à utiliser les marques, noms commerciaux, logos ou le symbole copyright d’Octapharma AG.
        </p>

        <p>
          Tous les liens vers le Site doivent faire l’objet d’un accord préalable exprès par Octapharma France SAS. Octapharma France SAS se réserve le droit de révoquer cette approbation à tout moment et à sa seule discrétion. En toute hypothèse, tout lien renvoyant au Site devra être retiré à première demande d’Octapharma France SAS.
        </p>

        <h3>Accès technique au site /virus</h3>

        <p>
          L’utilisation du Service proposé sur le Site nécessite du matériel compatible, un accès Internet et certains logiciels.
        </p>

        <p>
          Octapharma France SAS s’efforce par tous les moyens raisonnables de proposer aux Utilisateurs du Site un accès de qualité, mais n’est toutefois pas tenu à une obligation d’y parvenir.
        </p>

        <p>
          Le Site nécessite régulièrement des mises à jour et des maintenances qui peuvent conduire à une suspension de l’accès au Service, sans préavis et sans que cela ouvre droit à une quelconque indemnité. L’Utilisateur accepte ces contraintes techniques qui peuvent parfois atteindre la performance de l’accès au site sans que cela puisse être de nature à engager une quelconque responsabilité, notamment d’Octapharma France SAS. L’Utilisateur est seul responsable du bon fonctionnement de son équipement informatique et de son accès internet.
        </p>

        <p>
          De plus, Octapharma France SAS ne peut être tenue responsable de dysfonctionnements du réseau ou des serveurs, d’impossibilité d’accès au Site ou de tout autre évènement échappant à son contrôle comme, par exemple, des défaillances résultant d’une mauvaise installation de logiciel par les utilisateurs ou des restrictions imposées par les fournisseurs d’accès internet.
        </p>

        <p>
          Il n’est prévu aucune assistance technique vis-à-vis de l’Utilisateur que ce soit par des moyens électronique ou téléphonique.
        </p>

        <p>
          Octapharma France SAS ne peut garantir l’absence de virus ou d’autres composants nuisibles de son site ou de ces mailings ; elle ne peut garantir, non plus, l’interruption de ces mailings et ne prend aucune responsabilité quant à ces deux points.
        </p>

        <p>
          Octapharma France SAS et Octapharma AG (y compris leurs directeurs, filiales, officiers, employés, agents, entrepreneurs, successeurs et ayants-droit) ne prennent aucune responsabilité quant aux dégâts constatés sur votre matériel et causés par l’accès à ce site web, le téléchargement de son contenu ou la réception de courriel d’Octapharma AG et/ ou d’Octapharma France SAS dans le cadre de ses mailings.
        </p>

        <h3>Politique de confidentialité des données et utilisation de cookies</h3>

        <p>
          Octapharma France SAS respecte la réglementation en vigueur concernant les données personnelles. Pour connaître la politique d’Octapharma France SAS en matière de respect des données personnelles et vos droits d’accès, de modification, de rectification et de suppression des données, vous êtes invité à cliquer sur le lien suivant
          : <Link to={"/data-policy"}>« politique de confidentialité de données et cookies »</Link> d’Octapharma France SAS
        </p>

        <p>
          Vous êtes informés que lors de la visite de ce site, des cookies peuvent s’installer automatiquement sur leur logiciel de navigation.
        </p>

        <h3>Modification des conditions générales d’utilisation</h3>

        <p>
          Octapharma France SAS se réserve le droit de modifier et d’actualiser, à tout moment, et sans préavis ni notification, les Conditions Générales d’Utilisation (CGU).
        </p>

        <p>
          Par conséquent, les Utilisateurs sont invités à consulter régulièrement les CGU sur le Site et à en prendre intégralement connaissance.
        </p>

        <h3>Modification du site web</h3>

        <p>
          Octapharma France se réserve le droit de modifier, ajouter, supprimer ou corriger le contenu du site web à tout moment et sans préavis. Octapharma France se réserve également le droit d’interdire l’accès à ce site à tout moment et sans préavis.
        </p>

        <h3>Non validité de clauses</h3>

        <p>
          Si une ou plusieurs clauses ou stipulations des CGU étaient tenues pour nulles ou sans objet ou déclarées comme telles en application d’une loi, d’un règlement ou d’une décision définitive d’une juridiction, ladite clause ou stipulation sera réputée non écrite et l’ensemble des autres stipulations conserveront toute leur force et leur portée.
        </p>

        <div className={styles.end}>
        </div>

      </div>
    </div>
  )
}
