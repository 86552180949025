import React , { useState } from 'react';
import { Switch, Route , useLocation, useRouteMatch  } from "react-router-dom";
import { useTransition, animated } from 'react-spring';

import Nav from '../PARTIALS/Nav/Nav';
import UploadPage from '../ADMIN/Pages/upload/UploadPage';
import StockPage from './Pages/Stock/StockPage';
import ExpirationPage from './Pages/Expiration/ExpirationPage';

export default function AdminRouter() {

  const location = useLocation();
  const match = useRouteMatch();

  const transitions = useTransition(location, location => location.pathname, {
    from: { opacity: 0, transform: 'translate3d(0, 500px,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0px,0)' },
    leave: { opacity: 0, transform: 'translate3d(0px, -500px,0)'},
  })

  const [ activePage , setActivePage ] = useState("upload");

  return (
    <>
      <Nav
        activePage={activePage}
        />

        {transitions.map(({ item: location, props, key }) => (
          <animated.div key={key} style={props}>
            <Switch location={location}>

              <Route exact path={`${match.path}/upload`} render={
                () => <UploadPage
                setActivePage={setActivePage}
                />
              }/>
              <Route exact path={match.path} render={
                () => <StockPage
                setActivePage={setActivePage}
                />
              }/>
              <Route exact path={`${match.path}/expirations`} render={
                () => <ExpirationPage setActivePage={setActivePage}
                />
              }/>
            </Switch>
          </animated.div>
        ))}
    </>
  )
}
