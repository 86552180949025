 import React from 'react'
 export default function Month({ viewIsActive , drugs }) {
   
    const DrugRow = ({drugStock , i , count }) => {

      if (drugStock.history) {
        const { history } = drugStock;
        const stock = parseFloat(history['Month 0 Contingentement']);
        const order = parseFloat(history['Month 0']);
        const rest  = stock - order;
        return (
          <div className={`list-row${count > 1 && i !== count-1 ? " border" : ""}`}>
            <p className="bold">{drugStock.drug.name} </p>
            <p>{stock}</p>
            <p>{order}</p>
            <p className={rest < 0 ? "text-error" : "text-primary"}>{rest}</p>
          </div>
        )
      } else {
        return (
          <div className={`list-row${count > 1 && i !== count-1 ? " border" : ""}`}>
            <p className="bold">{drugStock.drug.name} <span className="lots">{drugStock.packs.length} lots</span></p>
          </div>
        )
      }
  
    }

    return (
      <>
        {viewIsActive &&
          <> 
            <div className="list-row labels">
              <p>Nom du médicament</p>
              <p>Contingentement du mois</p>
              <p>Commandé ce mois-ci</p>
              <p>Reste pour le mois en cours</p>
            </div>
            {drugs.map((item , i) => <DrugRow key={'item' + i} i={i} drugStock={item} count={drugs.length}/>)}
          </>
        }
      </>
    )
 }
 