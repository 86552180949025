export const colors = {
  primary:'#0EAF9C'
}

export const months = [
  "jan",
  "fév",
  "mar",
  "avr",
  "mai",
  "juin",
  "juil",
  "aôut",
  "sept",
  "oct",
  "nov",
  "déc"
];

export const cguDate = new Date("2021-02-05") 