import React from 'react';
import { Link } from 'react-router-dom';
import { TOOL_ID } from '../../actions';
import { putUserAuthAction } from '../../actions/authActions';
import { useGlobalContext } from '../../context/GlobalContext';

import Footer from '../PARTIALS/Footer/Footer';

import './auth.scss';

export default function ValidCgu() {


  const [ context, dispatch ] = useGlobalContext();

  const { userAuth } = context.auth;

  function valid() {
    let tools = userAuth.tools;
    let index = tools.findIndex(p => p.tool === TOOL_ID );
    tools[index].cguAcceptanceDate = new Date();
    putUserAuthAction(dispatch , { tools : tools });
  }

  return (
    <div className="login">

      <div className="col-img"></div>

      <div className="col-form">

        <p className="title bold">
          <span className="name">OCTAPPRO</span><br/>
          <span className="regular">BY </span><span>octapharma</span>
        </p>

        <form className="form-cgu">

          <Link to="/cgu">Lire les conditions générales d'utilisation</Link>

          <div className="btn-container">
            <button
              onClick={() => valid()}
              className="btn primary"
              type="button">Accepter les conditions<br/>générales d'utilisation </button>
          </div>

        </form>
        
        <div className="footer">
          <Footer />
        </div>

      </div>

    </div>
  )
}
