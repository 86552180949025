import { postData , getData } from './index';
import { months } from '../utils/constants';
import {
  ERROR_UPLOAD,
  POST_STOCK,
  POST_EXPIRATIONS,
  ERROR_STOCK,
  GET_STOCK,
  GET_PHARMACY_STOCK
} from "./types"

export async function postStockFileAction(dispatch , formData ){
  let url = "/stock/upload/list",
      stock;

  await postData(POST_STOCK , ERROR_UPLOAD , url, dispatch , formData , true ).then((response)=>{
    stock = response
  });

  dispatch({
    type : POST_STOCK ,
    payload : "stock " + stock.message
  });

};


export async function postExpirationsFileAction(dispatch , formData ){
  let url = "/stock/upload/pack",
      expiration;

  await postData(POST_EXPIRATIONS , ERROR_UPLOAD , url, dispatch , formData , true ).then((response)=>{
    expiration = response
  });

  dispatch({
    type : POST_EXPIRATIONS ,
    payload : "expiration " + expiration.message
  });

};

export async function getPharmacyStockAction(dispatch , pharmacyId ) {
  let url = '/stock';
  let stockList;
  await getData(ERROR_STOCK , url , dispatch , true ).then((response) => {
    stockList = response.stocks.filter(item => item.client && item.client._id === pharmacyId )
  });

  stockList.forEach((product) => {

    if (product.packs.length) {
      product.drug.name = `${product.drug.name} (UI)`
      product.drug.packs = product.packs
    } else {
      product.drug.name = `${product.drug.name} (Gr)`
    }

    if (product.history) {
      product.stock = parseFloat(product.history['Month 0 Contingentement']);
      product.order = parseFloat(product.history['Month 0']);
      product.rest  = product.stock - product.order;

      //CALC HISTORY
      var history = [];
      var date = new Date(product.currentDate);
      for (const property in product.history) {
        let number = property.replace('Month ','')
        if (parseFloat(number) <= 0 && property !== 'Month 0 Contingentement') {
          let i = Math.abs(parseInt(number))
          let month = date.getMonth() - i;
          let label = `${months[month]} ${date.getFullYear()}`
          if(month < 0) {
            month = 12 - Math.abs(month)
            label = `${months[month]} ${date.getFullYear() -1}`
          }
          history.push({
            month: label,
            value: product.history[property]
          });
        }
      }
      product.stockHistory = history

      var planned = []
      for (const property in product.history) {
        let number = property.replace('Month ','')
        if (parseFloat(number) >= 0 && property !== 'Month 0') {
          let i = Math.abs(parseInt(number))
          let month = date.getMonth() + i;
          let label = `${months[month]} ${date.getFullYear()}`
          if(month - 12 >= 0) {
            month = month - 12
            label = `${months[month]} ${date.getFullYear()+1}`
          }
          planned.push({
            month: label,
            value: parseInt(product.history[property])
          });
        }
      }
      product.plannedStock = planned
    }
  });

  if (stockList[0] && stockList[0].client) {
    dispatch({
      type : GET_PHARMACY_STOCK ,
      payload : {
        pharmacist: stockList[0].client,
        stocksList: stockList,
      }
    });
  }

}

export async function getStockListAction(dispatch) {
  let url = '/stock';
  let stockList;

  await getData(ERROR_STOCK , url , dispatch , true ).then((response) => {
    stockList = response.stocks
  });

  let clientListStock = []
  if (stockList) {
    stockList.forEach(stock => {
      if (stock.client && !clientListStock.find(client =>  client._id === stock.client._id)) {
        let client = stock.client;

        //ADD DRUGS TO CLIENT
        let drugs = stockList.filter(item => item.client && item.client._id === stock.client._id);
        drugs.forEach(item => {
          if (item.packs.length > 0) {
            item.drug.name = `${item.drug.name} (UI)`
            item.drug.packs = item.packs
          } else {
            item.drug.name = `${item.drug.name} (Gr)`
          }
        })
        client.drugs = [...drugs]

        //ADD TOTAL STOCK
        let totalStock = 0; 
        drugs.forEach(drug => {
          if (drug.history) totalStock += parseFloat(drug.history['Month 0 Contingentement']);
        });
        client.totalStock = totalStock;

        //ADD COMMENT TO CLIENT
        let comments =[]
        client.totalComments = drugs.filter(item => {          
          if (item.history && item.history['Comment Octapharma']) {
            comments.push({
              drug: item.drug.name,
              type: "Interne",
              comment: item.history['Comment Octapharma']
            })
            return item.history['Comment Octapharma'] ? true : false 
          }
          if (item.history && item.history['Comment Pharmacist']) {
            comments.push({
              drug: item.drug.name,
              type: "Externe",
              comment: item.history['Comment Pharmacist']
            })
            return item.history['Comment Pharmacist'] ? true : false 
          }
          return false;
        }).length;
        client.comments = comments

        clientListStock.push(client);
      }
    });
  }

  let stockUpdatedAt = null;
  clientListStock.forEach(client => {
    client.drugs.forEach(d => {
      if (!stockUpdatedAt) {
        stockUpdatedAt = new Date(d.updatedAt)
      }
      if (stockUpdatedAt && new Date(stockUpdatedAt) < new Date(d.updatedAt)) {
        stockUpdatedAt = new Date(d.updatedAt)
      }
    })
  });

  let clientExpirationsList = [];

  let expirationUpdatedAt = null;

  clientListStock.forEach(item => {
    
    var drugs = [];

    item.drugs.forEach(drug => {
       if (drug.type === "pack" && drug.packs.length > 0) {
        if (!expirationUpdatedAt) {
          expirationUpdatedAt = new Date(drug.updatedAt)
        }
        if (new Date(drug.updatedAt) > expirationUpdatedAt) {
          expirationUpdatedAt = new Date(drug.updatedAt)
        }
        return drugs.push(drug);
       }
    })
    if (drugs.length) {
      clientExpirationsList.push(item);
    }
  });

  dispatch({
    type : GET_STOCK ,
    payload : {
      stocksList: clientListStock ,
      expirationsList : clientExpirationsList,
      stockUpdatedAt: stockUpdatedAt ,
      expirationUpdatedAt: expirationUpdatedAt
    }
  });

}