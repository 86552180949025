import React from 'react';
import Logo from '../PARTIALS/Logo/Logo';
import styles from './PageMobile.module.scss';
import { AiOutlineDesktop } from 'react-icons/ai';

export default function PageMobile() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.logo}>
          <Logo />
          <h1>Octasmart</h1>
        </div>

        <div className={styles.message}>
          <AiOutlineDesktop size={30} color={'#999EB0'} /> 
          <p>Application uniquement disponible sur desktop</p>
        </div>

      </div>
    </div>
  )
}
