import React , { useState , useMemo , useRef , useEffect } from 'react';
import { BiPlus , BiMinus } from 'react-icons/bi';
// import commentIcon from '../../../../../assets/images/icons/comment.svg'

import { format } from 'date-fns';

import { colors } from '../../../../../utils/constants';

import './clientRow.scss';
// import Comments from './Comments/Comments';

export default function ClientRow({ client , view , index }) {

  const { profile , drugs , code  } = client;
  const [ viewIsOpen , setViewIsOpen ] = useState();
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current.clientHeight) {
      setViewIsOpen(index === 0 ? true :false)
    }
    // eslint-disable-next-line
  }, [contentRef?.current?.clientHeight])

  function dayDiff(peremptionDate) { 
    let oneDay = 86400000;
    let date = new Date(peremptionDate).getTime() / oneDay ;
    let today = new Date().getTime() / oneDay ;
    return (date - today).toFixed(0);
  }

  return useMemo(() => (
    <>
      {profile &&
        <>
          <div className="box">
            <div className="main-row">
              <div className="main-col structure">
                <p className="bold name">{profile && profile.structure && profile.structure}</p>
              </div>
              <div className="main-col">
                <p>{code}</p>
              </div>
              <div className="main-col">
                <p>{profile && profile.dept}</p>
              </div>
              <div className="main-col">
                <p>{profile && profile.city}</p>
              </div>
              <div className="main-col drugs">
                <p>{drugs && drugs.filter(item => !item.history).length}</p>
              </div>
              <div className="main-col">
                {/* <p>{totalComments}</p>
                {totalComments > 0 &&
                  <button
                    onClick={() => setCommentIsOpen(!commentsIsOpen)}
                    className="comment-toggle">
                    <img src={commentIcon} alt="comment" />
                  </button>
                } */}
              </div>
              <button
                onClick={() => setViewIsOpen(!viewIsOpen)}
                className={`view-toggle${viewIsOpen? " isActive" : ""}`}>
                {!viewIsOpen ? <BiPlus size={38} color={colors.primary}/> : <BiMinus size={38} color={colors.primary}/>}
              </button>
            </div>
            <div className="expiration" style={{ height: viewIsOpen && contentRef?.current?.clientHeight ? contentRef.current.clientHeight + 40 : 0 }}>
              <div className="list-lots" ref={contentRef}>
                {drugs.filter(item => item.packs.length > 0 ? true : false ).map((item , i) =>  
                  <div key={"peremption-row" + i}>
                    <p className="bold name">{item.drug.name}</p>
                    <div className={`lot labels`}>
                      <p className="number">Dosage</p>
                      <p className="number">Quantité</p>
                      <p className="number">Date</p>
                      <p className="number">Commande</p>
                      <p className="number">Lot</p>
                      <p className="date">Péremption</p>
                      <p className="number right">Jours restants</p>
                    </div>
                  
                    {item.packs.map((item , i ) => {
                      return (
                        <div key={"lot" + i} className={`lot border`}>
                          <p className="number"><span className="bold">{item.dosage}</span></p>
                          <p className="number"><span className="bold">{item.quantity}</span></p>
                          <p className="number"><span className="bold">{format(new Date(item.date) , "dd/MM/yyyy")}</span></p>
                          <p className="number"><span className="bold">{item.invoice}</span></p>
                          <p className="number"><span className="bold">{item.number}</span></p>
                          <p className="date"><span className="bold">{item.expirationDate !== "Invalid Date" && format(new Date(item.expirationDate) , "dd/MM/yyyy")}</span></p>
                          <p className="number right"><span className={`bold ${dayDiff(item.expirationDate) < 0 ? "text-error" : "text-primary"}`}>{item.expirationDate !== "Invalid Date" && dayDiff(item.expirationDate)} jours</span></p>
                        </div>
                      )
                    })}
                  </div>
                )}
                </div>
            </div>
          </div>
        </>
      }
    </>
    // eslint-disable-next-line 
  ),[view , viewIsOpen , index , client ,  drugs])
}
