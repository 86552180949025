import React from 'react'
import './comments.scss';
export default function Comments({ comments , isOpen , clientId }) {

  function getViewHeight() {
    var rowHeight = 62;
    if (!isOpen) return 0 
    return rowHeight * comments.length
  }

  return (
    <div className={`comments${isOpen? " isActive" : ""}`} style={{ height: getViewHeight() }}>
      {isOpen &&
        <div className="comments-list">
          {comments.map((item , i) => {
            return (
              <div key={'comment' + clientId + i} className={`comment${comments.length > 1 && i !== comments.length-1 ? " border" : ""}`}>
                <p className="drug bold">{item.drug}</p>
                <div className="text">
                  <p>{item.comment}</p>
                  <p className="text-primary bold">{item.type}</p>
                </div>
              </div>
            )
          })}
        </div>
      }
    </div>
  )
}
