import React  from 'react';
import { Link } from 'react-router-dom';
import { BsArrowLeftShort } from "react-icons/bs";
import styles from './Legal.module.scss';

export default function Cgu() {

  return (
    <div className={`${styles.container}`}>
      <Link to="/">
        <div className={styles.back}>
          <BsArrowLeftShort size={24} />
          Retour
        </div>
      </Link>
      <div className={styles.content}>
        <h1>Conditions générales d'utilisation</h1>

        <h3>1) Éditeur de la plateforme</h3>

        <p>
          La Plateforme « OCTASMART » (ci-après désignée « Plateforme ») est éditée par la société OCTAPHARMA, S.A.S dont le siège social est situé au 62 bis, André Morizet 92100 Boulogne Billancourt] et immatriculée au Registre du Commerce et  des  Société  de  Paris  sous  le  numéro  45132730800024  (ci-après  désignée  « la  Société »  ou  « OCTAPHARMA »), représentée par Marie-Christine Borrelly.
        </p>

        <p>
          Directeur de la publication : Marie-Christine Borrelly<br/>
          Responsable de la rédaction : Marie-Christine Borrelly<br/>
          Téléphone : 01 41 31 80 00
        </p>

        <h3>2) Hébergeur</h3>

        <p>
          La société Octapharma, dont le siège social est situé au 62 bis, André Morizet Boulogne Billancourt – France et immatriculée au Registre du Commerce et des Sociétés de Paris sous le numéro 45132730800024<br/>
          Tél. : 0141318000
        </p>

        <h3>3) Définitions</h3>

        <p>
          Les termes ci-après définis ont le sens et la portée donnés dans leur définition dans le cadre de la conclusion et l’exécution des Conditions Générales d’Utilisation de la Plateforme (CGU).
        </p>

        <p>
          <span className="bold">« Compte  Personnel  » </span>
          désigne  le  compte  créé  par  l’Utilisateur  Pharmacien  lors  de  sa  première  connexion  à  la Plateforme lui permettant d’accéder et d’utiliser les Services proposés au sein de la Plateforme de manière sécurisée;
        </p>

        <p>
          <span className="bold">« Conditions Générales d’Utilisation » ou « CGU » </span>
          désigne les différentes conditions générales d’utilisation destinées à  encadrer  contractuellement  l’utilisation  de  la  Plateforme  OCTASMART  ainsi  que  tous  les  Services  qui  y  sont accessibles. L’Utilisateur Pharmacien reconnait que chaque Service accessible à partir de la Plateforme OCTASMART est  encadré  par  ses  propres  CGU.  Les  présentes  conditions  générales  d’utilisation  ont  pour  objet  d’encadrer contractuellement l’utilisation du Service OCTAPPRO par les Utilisateurs Pharmaciens ;
        </p>

        <p>
          <span className="bold">« Contenu » </span>
          désigne l’ensemble des textes, photographies, vidéos, illustrations, fonctionnalités et plus largement tout élément existant au sein de la Plateforme et des Services publié par OCTAPHARMA ;
        </p>

        <p>
          <span className="bold">« Documents contractuels » </span>
          désigne les CGU de la Plateforme OCTASMART, acceptées par l’Utilisateur Pharmacien lors de sa première connexion à la Plateforme, les présentes CGU, acceptées par l’Utilisateur Pharmacien lors de sa première  connexion  au  Service  OCTAPRO,  ainsi  que  la  Politique  de  confidentialité  de  la  Plateforme.  L’Utilisateur Pharmacien reconnait que chaque Service accessible à partir de la Plateforme OCTASMART est encadré par ses propres CGU ;
        </p>
        
        <p>
          <span className="bold">« Plateforme » ou  « Plateforme  OCTASMART »</span>
          désigne  les  applicatifs  conçus,  développés  et  maintenus  par OCTAPHARMA,   destinés   à   permettre   aux   Utilisateurs   Pharmaciens   d’accéder   et   d’utiliser   le   Contenu   du Service OCTAPPRO ;
        </p>

        <p>
          <span className="bold">« Règlementation » </span>
          désigne conjointement la Loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés modifiée (dite « Loi Informatique et Libertés ») et le Règlement UE 2016/679 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données du 27 avril 2016 (dit « RGPD »).
        </p>

        <p>
          <span className="bold">« Services » </span>
          désigne  l’ensemble  des  Services  et  Contenus  fournis  par  OCTAPHARMA  accessibles  à  partir  de  la Plateforme  et  incluant  le  Service  OCTAPPRO ;  chaque  Service  est  soumis  à  ses  propres  conditions  générales d’utilisation ;
        </p>

        <p>
          <span className="bold">« Service OCTAPPRO » </span> ou <span className="bold">« Services » </span>
          désigne le service de gestion du contingentement et des dates de péremption des  médicaments  d’intérêt  thérapeutiques  majeurs  exploités  par  OCTAPHARMA  dont  l'objet  est,  dans  l'intérêt  des patients, de prévenir et de pallier toute rupture de stock et donc de prévenir tout retard de prise en charge du patient conformément aux dispositions de l’article L. 5121-31 Code de la santé publique. 
        </p>


        <p>
          <span className="bold">« Utilisateur Pharmacien » </span>
          désigne  la  personne  physique  exerçant  la  profession  de  docteur  en  pharmacie,  titulaire d’un diplôme visé aux articles R. 5126-2 à R. 5126-7-1 du Code de la santé publique lui permettant d’exercer au sein d’une pharmacie à usage intérieur, et dont l’accès à la Plateforme et au Service OCTAPPRO a été validé par la Société OCTAPHARMA.
        </p>

        <p>
          Conformément au II de l’article L. 5126-3 Code de la santé publique, peuvent également accéder au Service OCTAPPRO sous  l’autorité  du  pharmacien  chargé  de  la  gérance,  les  personnes  autorisées  au  sens  du  titre  IV  du  livre  II  de  la quatrième partie du Code de la santé publique ainsi que d'autres catégories de personnels spécialisés qui sont affectés à la pharmacie à usage intérieur à raison de leurs compétences.
        </p>

        <p>Sauf indication contraire, dans les présentes Conditions Générales d’Utilisation :</p>

        <ul>
          <li>Les mots au singulier incluent le pluriel et inversement ;</li>
          <li>Les mots au féminin incluent le masculin et inversement ;</li>
          <li>Une référence à un document, une norme, une disposition législative, une disposition réglementaire, code ou tout autre document implique toute modification ou mise à jour de ce document, norme, disposition législative, disposition réglementaire ou code. </li>
        </ul>

        <h3>4) Documents contractuels</h3>

        <p>
          Chaque  Utilisateur  Pharmacien  souscrit  aux  Conditions  Générales  d’Utilisation  de  la  Plateforme  et  aux  Conditions Générales d’Utilisation du Service avant d’accéder au Service OCTAPPRO par le biais de cases à cocher.
        </p>

        <p>
          Chaque Utilisateur Pharmacien reconnait avoir pris connaissance de la <a href='https://octasmart.octapharma.bakasable.io/data-policy' rel="noreferrer" target="_blank">Politique de confidentialité</a> et être informé du traitement de ses données à caractère personnel par OCTAPHARMA. 
        </p>

        <p>
          Les  CGU  de  la  Plateforme,  la  Politique  de  confidentialité  et  les  CGU  du  Service  forment  ensemble  les  Documents Contractuels.
        </p>
        
        <p>
          L’invalidité d’une disposition des présentes CGU ainsi jugée par un tribunal compétent n'en affecterait pas pour autant la validité des autres dispositions des présentes.
        </p>

        <p>
          Toute renonciation à l’une des dispositions des CGU ou à d’autres dispositions des présentes ne peut valoir renonciation définitive à l’ensemble des CGU.
        </p>

        <h3>5) Objet</h3>

        <p>
          Les présentes CGU sont destinées à encadrer et définir les règles d’utilisation du Service OCTAPPRO accessible via la Plateforme, ainsi que les droits et obligations des Utilisateurs Pharmaciens.
        </p>

        <h3>6) Entrée en vigueur et durée des conditions générales</h3>

        <p>
          L’Utilisateur Pharmacien est invité à lire attentivement les CGU et dispose de la possibilité de les consulter à tout moment au sein de la Plateforme. 
        </p>

        <p>
          L’Utilisateur Pharmacien accepte les présentes CGU lors de sa première connexion au Service OCTAPPRO. 
        </p>

        <p>
          De manière générale, l’Utilisateur Pharmacien reconnait que l’expression de son consentement aux CGU est matérialisée par l’activation d’une case à cocher associée à une convention de preuve définie aux présentes CGU.
        </p>

        <p>
          Les Services ne peuvent être fournis qu’après acceptation des CGU.
        </p>

        <h3>7) Conditions d'accès et d'utilisation</h3>

        <p>
          L'accès et l’utilisation du Service OCTAPPRO par tout Utilisateur Pharmacien sont soumis aux présentes CGU, aux lois en vigueur concernant les services de communication électronique au public, et plus généralement au respect de toutes les lois applicables.
        </p>

        <p>
          Les Utilisateurs Pharmaciens ne peuvent utiliser le Service OCTAPPRO fournis dans le cadre de la Plateforme à d’autres fins que pour la destination définie aux présentes CGU, et notamment, s’interdisent d’utiliser le Service, à toute fin publicitaire ou promotionnelle.
        </p>

        <p>
        Toute autre utilisation en fraude des droits de la Société expose l’Utilisateur Pharmacien à des poursuites.
        </p>

        <p>
          L’Utilisateur Pharmacien reconnaît qu’il accède à un Service mis à disposition sur une Plateforme aux modalités d’accès sécurisées. Il s’engage à ne pas communiquer ses éléments d’authentification à des tiers.
        </p>

        <p>
        Il reconnaît que l’utilisation du Service ne saurait l’exonérer de son obligation de prendre les décisions concernant le patient dans le strict respect des règles relatives à l’exercice de sa profession. 
        </p>

        <p>
        L’Utilisateur Pharmacien reconnaît prendre les décisions relatives à ses patients conformément aux règles professionnelles et déontologiques de sa profession. 
        </p>

        <p>
        L’Utilisateur Pharmacien reconnaît et accepte que l’intégralité des frais de connexion au réseau internet reste à sa charge exclusive. 
        </p>

        <p>
          L’Utilisateur Pharmacien s’engage à utiliser le Service en bon père de famille au sens des dispositions du code civil mais également de la jurisprudence actuelle. Toute utilisation manifestement abusive du Service entraînera la possibilité pour OCTAPHARMA de limiter ou de suspendre tout ou partie du Service.
        </p>

        <p>
        De façon générale, toute utilisation non conforme aux CGU entraînera la possibilité pour OCTAPHARMA de suspendre l’accès au Service. 
        </p>

        <h4>7.1) Configuration requise</h4>
        
        <p>
        L’Utilisateur Pharmacien est informé que l’utilisation du Service nécessite de disposer de certaines configurations ci-après définies : une connexion internet suffisamment rapide et un ordinateur. 
        </p>

        <h4>7.2) Création du compte personnel</h4>

        <p>
          L’Utilisateur Pharmacien est informé que l’accès au Service est subordonné à la création d’un Compte Personnel sur la Plateforme selon les modalités décrites au sein de l’article 7.2 des CGU de la Plateforme accessible via le lien hypertexte suivant octappro.octapharma.bakasable.io/cgu
        </p>

        <h4>7.2) Connexion du compte personnel et accés au service OCTAPPRO</h4>

        <p>
          L’Utilisateur Pharmacien est informé que l’accès au Service OCTAPPRO nécessite qu’il se soit connecté préalablement à son Compte Personnel sur la Plateforme.
        </p>

        <p>
          Une fois connecté à son Compte Personnel, l’Utilisateur Pharmacien pourra accéder au Contenus de la Plateforme ainsi qu’aux Services auxquels il aura obtenu l’accès auprès des équipes d’OCTAPHARMA.
        </p>

        <p>
          Pour accéder au Service OCTAPPRO, l’Utilisateur Pharmacien devra saisir une nouvelle fois son identifiant et son mot de passe.
        </p>

        <p>
          L’Utilisateur Pharmacien reconnait qu’il est seul responsable de l'usage qu’il fait de son mot de passe et identifiant.
        </p>

        <p>
          L'identification et l’authentification de l’Utilisateur Pharmacien au moyen du mot de passe et du login (son adresse email) à l’occasion de l’accès à son Compte Personnel vaut de manière irréfragable imputabilité à l’Utilisateur Pharmacien des opérations effectuées une fois connecté à son Compte Personnel au moyen de ce mot de passe et de ce login, dans les conditions définies à l’article « Convention de preuve » des présentes CGU.
        </p>

        <p>
        Autrement dit, toute action réalisée par l’Utilisateur Pharmacien via son Compte Personnel, à l’occasion de son utilisation du Service, à partir de ses éléments d’authentification sera réputée avoir été effectuée par l’Utilisateur Pharmacien et sous sa responsabilité exclusive.
        </p>

        <p>
         A ce titre, l’Utilisateur Pharmacien s’engage à garder secrets ses éléments d’authentification étant entendu que OCTAPHARMA ne pourra être tenue pour responsable de toute perte ou tout dommage survenant en cas de manquement à cette obligation, toute utilisation des éléments précités étant faite sous l’entière responsabilité de l’Utilisateur Pharmacien.
        </p>

        <p>
          En cas de perte ou de vol de ses éléments d’authentification, ou encore de suspicion de leur utilisation par un tiers non autorisé, l’Utilisateur Pharmacien s'engage à en informer sans délai OCTAPHARMA, à l’adresse mail suivante : fr2dpo@octapharma.com
        </p>

        <p>
          L'opposition notifiée par l’Utilisateur Pharmacien en cas de perte ou de vol ou de suspicion d’utilisation par un tiers non autorisé de ses éléments d’authentification prendra effet immédiatement à compter de cette notification.
        </p>

        <p>
        Les éléments d’authentification de l’Utilisateur Pharmacien existants seront désactivés par OCTAPHARMA, dans les meilleurs délais et de nouveaux éléments d’authentification provisoires seront adressés à l’Utilisateur Pharmacien. L’Utilisateur Pharmacien devra alors modifier son mot de passe dès la connexion suivante.
        </p>

        <p>
        Conformément aux recommandations de la CNIL, un dispositif technique incite l’Utilisateur Pharmacien à changer périodiquement de mot de passe, tous les 12 mois.
        </p>

        <p>
          Si l’Utilisateur Pharmacien oublie son mot de passe, il peut cliquer sur " Mot de passe oublié ?". Après avoir renseigné son adresse e-mail, l’Utilisateur Pharmacien recevra directement par courrier électronique un lien pour réinitialiser son mot de passe. 
        </p>

        <div className={styles.bloc}>

          <h4>Mise en garde</h4>

          <p>
            L’Utilisateur s’engage à saisir et déclarer les données qui permettent son identification sous ses entières responsabilité, contrôle et direction et s’engage à communiquer des informations complètes, exactes et actualisées, et à ne pas usurper l’identité d’un tiers.
          </p>

          <p>
            Dans l’hypothèse où l’Utilisateur communiquerait des informations fausses, inexactes, trompeuses, incomplètes ou périmées, OCTAPHARMA se réserve la possibilité de suspendre ou refuser l’accès, sans préavis ni indemnité, de façon temporaire ou définitive, à tout ou partie de la Plateforme, et de ses Services. En outre, dans de tels cas, OCTAPHARMA décline toute responsabilité du fait de l’utilisation de la Plateforme, et de ses Services.
          </p>

        </div>


        <h3>8) PRÉSENTATION DU SERVICE OCTAPPRO</h3>

        <p>
          Le Service OCTAPPRO s’inscrit dans le champ de l’obligation légale d’OCTAPHARMA d’élaborer et de mettre en œuvre des plans de gestion des pénuries pour ses médicaments d’intérêt thérapeutique majeur dans le respect de l’article L. 5121-31 CSP. 
        </p>

        <p>
        Le Service OCTAPPRO est un service de gestion du contingentement et des dates de péremption de certains médicaments d’intérêt thérapeutique majeur dont l'objet est, dans l'intérêt des patients, de prévenir et de pallier toute rupture de stock et donc de prévenir tout retard de prise en charge du patient. Ce service concerne certains médicaments pour lesquels la Pharmacie de l’hôpital en question est en marché avec OCTAPHARMA.
        </p>

        <p>
        Ce Service est destiné uniquement à l’Utilisateur Pharmacien qui déclare l’utiliser dans le champ de ses obligations légales, professionnelles et déontologiques.
        </p>

        <p>
        Le Service OCTAPPRO est exclusivement réservé aux Utilisateurs Pharmaciens.
        </p>

        <p>
        L’Utilisateur Pharmacien qui souscrit au Service OCTAPPRO déclare et garantit être titulaire d’un diplôme lui permettant d’exercer sa profession et d’utiliser le Service OCTAPPRO dans son cadre professionnel.
        </p>

        <p>
        En cas d’interdiction d’exercer sa profession, provisoire ou définitive, l’Utilisateur Pharmacien s’interdit d’utiliser le Service OCTAPPRO.
        </p>

        <p>
        Ce Service contient des fonctionnalités paramétrables par l’Utilisateur Pharmacien en fonction de ses besoins.
        </p>

        <p>
        Notamment, l’Utilisateur Pharmacien peut paramétrer le Service pour recevoir des notifications de rappel. 
        </p>

        <p>
          Le paramétrage des notifications de rappel est laissé sous l’entière responsabilité de l’Utilisateur Pharmacien qui est maître de leur activation ou de leur désactivation. 
        </p>

        <p>
        Les notifications de rappel sont émises à titre indicatif sur la base des seules informations renseignées par l’Utilisateur Pharmacien sous sa responsabilité.
        </p>

        <p>
          De manière générale, le Service OCTAPPRO, y compris ses fonctionnalités, est proposé à l’Utilisateur Pharmacien qui reconnait l’utiliser sous ses seules contrôles, direction et responsabilité et accepte que l’utilisation qu’il en fait ne dilue ni totalement, ni partiellement l’ensemble de ses obligations légales et professionnelles et que le Service ne s’y substitue pas.
        </p>

        <p>
          Le Service OCTAPRO n’est pas une incitation à acheter, prescrire, promouvoir ou recommander, que ce soit maintenant ou à l’avenir, des produits ou services d’OCTAPHARMA. Le Service n’est pas davantage une incitation à modifier le traitement actuel de patients au profit d’un autre traitement, et notamment de produits d’OCTAPHARMA.
        </p>

        <div className={styles.bloc}>

          <h4>Mise en garde</h4>

          <p>
          OCTAPHARMA ne peut être tenue responsable de l'interprétation ou de l’utilisation par l’Utilisateur Pharmacien du Service et Contenus et informations qui y sont diffusés, ni des conséquences qui en découlent.
          </p>

          <p>
          de manière générale, l’Utilisateur Pharmacien reconnait que l’utilisation qu’il fait des informations et des fonctionnalités du Service se fait sous ses seuls contrôle, direction et responsabilité. 
          </p>

        </div>

        <h3>9) Responsabilité</h3>

        <p>
        L’Utilisateur Pharmacien est responsable de l’utilisation qu’il fait du Service OCTAPPRO et en particulier s’engage à effectuer toutes les vérifications nécessaires et opportunes. 
        </p>

        <p>
          L’Utilisateur Pharmacien demeure pleinement et personnellement responsable de l’utilisation du Service OCTAPPRO dans les conditions de droit commun. Il s’engage à valider l’adéquation du Service OCTAPPRO à ses besoins. Il reconnaît qu’il prend seul toute décision fondée sur le Service OCTAPPRO et Contenus fournis par le biais de la Plateforme. 
        </p>

        <p>
        De manière générale, l’Utilisateur Pharmacien s’engage à utiliser le Service OCTAPPRO :
        </p>

        <ul>
          <li>
          dans le respect des lois, règlementation et droits des tiers, notamment des droits de propriété intellectuelle et industrielle ;
          </li>
          <li>
          de manière loyale et conformément à sa destination. 
          </li>
        </ul>

        <p>
          Il appartient notamment à l’Utilisateur Pharmacien :
        </p>

        <ul>
          <li>
            de satisfaire à ses obligations en matière de sécurité, conformément à l’article « Sécurité » des présentes CGU ;
          </li>
          <li>
          d’utiliser le Service OCTAPPRO dans le respect des CGU et des dispositions légales et règlementaires en vigueur ;
          </li>
          <li>
          de ne pas commercialiser tout ou partie du Service OCTAPPRO accessible via la Plateforme ;
          </li>
          <li>
          de s’assurer qu’il dispose de la compétence nécessaire et du personnel qualifié pour l’utilisation du Service OCTAPPRO accessible via la Plateforme. 
          </li>
        </ul>

        <p>
          En cas de non-respect d’une ou plusieurs dispositions des présentes CGU, l’accès de l’Utilisateur Pharmacien au Service OCTAPPRO pourra être, unilatéralement, de plein droit et sans préavis, temporairement suspendu ou définitivement bloqué. 
        </p>

        <p>
          OCTAPHARMA est tenue à une obligation de moyens dans le cadre de la mise à disposition du Service OCTAPPRO pour ce qui la concerne. L’Utilisateur Pharmacien reconnaît que OCTAPHARMA ne pourra être tenue pour responsable de tout dommage matériel ou immatériel, direct ou indirect, quelles qu’en soient les causes (y compris les dommages pouvant être causés par l’éventuelle diffusion de virus, par la fraude informatique, du fait des contraintes et limites du réseau internet ou encore de la perte, de la détérioration ou de l’altération de fichiers), ni des conséquences résultant :
        </p>

        <ul>
          <li>de son utilisation du Service OCTAPPRO </li>
          <li>
          de l’impossibilité d’avoir accès au Service OCTAPPRO, à l’exception des dommages directs consécutifs à une faute lourde ou intentionnelle.
          </li>
        </ul>

        <p className="bold">
          L’UTILISATION DES SERVICES NE DILUE, NE MODIFIE OU N’ATTENUE NI DIRECTEMENT, NI INDIRECTEMENT LA RESPONSABILITE ET LES OBLIGATIONS DES UTILISATEURS PHARMACIENS. LES UTILISATEURS PHARMACIENS EXERCENT LEUR PRATIQUE PROFESSIONNELLE EN TOUTE INDEPENDANCE, SELON LEURS OBLIGATIONS DEONTOLOGIQUES, LEGALES ET REGLEMENTAIRES ET SOUS LEUR RESPONSABILITE EXCLUSIVE.
        </p>

        <h3>10) Propriété intellectuelle</h3>
        
        <p>
        OCTAPHARMA est titulaire des droits de propriété intellectuelle et industrielle du Service OCTAPPRO. L’utilisation du Service OCTAPPRO ne confère en aucun cas aux Utilisateurs Pharmaciens un droit de propriété sur le Service et Contenus accessibles par le biais de la Plateforme.
        </p>

        <h4>
         10.1) Marques, signes distinctifs et logos
        </h4>

        <p>
        Sauf précision contraire, toutes les marques utilisées dans le cadre du Service OCTAPPRO et Contenus qui sont accessibles via la Plateforme sont la propriété de OCTAPHARMA.
        </p>

        <p>
        Sauf autorisation expresse et préalable de OCTAPHARMA, toute reproduction, (totale ou partielle) et usage de ces marques, figuratives ou non, appartenant à OCTAPHARMA expose l’Utilisateur Pharmacien à des poursuites judiciaires.
Bases de données
        </p>

        <h4>
         10.2) Bases de données
        </h4>

        <p>
        Conformément aux dispositions du Code de la Propriété intellectuelle concernant la protection des bases de données, OCTAPHARMA est producteur et propriétaire de tout ou partie des bases de données, leur structure et leurs contenus, composant le Service ou produites dans le cadre de la mise en œuvre du Service.
        </p>

        <p>
        En accédant au Service OCTAPPRO, l’Utilisateur Pharmacien reconnaît que les données le composant ou produites dans le  cadre de la mise en œuvre du Service sont légalement protégées, et qu’il lui est interdit notamment d’extraire, réutiliser, stocker, reproduire, représenter ou conserver, directement ou indirectement, sur un support quelconque, par tout moyen et sous toute forme que ce soit, tout ou partie qualitativement ou quantitativement substantielle, du contenu des bases de données figurant au sein du Service auquel il accède, ainsi que d’en faire l’extraction ou la réutilisation répétée et systématique de parties qualitativement et quantitativement non substantielles, lorsque ces opérations excèdent manifestement les conditions d’utilisation normale.
        </p>

        <h4>
         10.3) Service
        </h4>

        <p>
          Toute utilisation, reproduction, copie, diffusion du Service pour un usage autre que celle prévu au sein des présentes CGU est interdite.
        </p>

        <p>
          L’ensemble du Service, incluant de manière non limitative, le nom de domaine, les textes, la charte graphique, les graphiques, les photographies, les dessins, les sons, les images, audio et vidéo, mais également l’arborescence, le plan de navigation, la conception et l’organisation de ses rubriques, leurs titres, existants ou à venir, est protégé par des droits de propriété intellectuelle, détenus ou revendiqués par OCTAPHARMA, avec l’autorisation des titulaires de ces droits, le cas échéant.
        </p>

        <p>
          Le Service est destiné à l’information et l’usage personnels de l’Utilisateur Pharmacien qui bénéficie d’un droit d’usage privé, non collectif et non exclusif.
        </p>

        <p>
          Sauf  autorisation  expresse  et  préalable  de  OCTAPHARMA  sont  interdites  toutes  reproductions,  représentations  et utilisations par l’Utilisateur Pharmacien autres que celles visées ci-dessus et notamment :
        </p>

        <ul>
          <li>
            toute  adaptation,  mise  à  disposition  du  public  à  sa  demande  ou  non,  distribution,  rediffusion  sous  quelque forme que ce soit, mise en réseau, communication publique, à titre gratuit ou onéreux, de tout ou partie des œuvres,  prestations,  et  tous  éléments  protégés  ou  susceptibles  de  protection  par  le  droit  de  la  propriété intellectuelle reproduits au sein du Service ;
          </li>
          <li>
            tout  lien,  accès,  modification,  ajout,  suppression  qui  porte  sur  le  système  de  traitement  automatisé  de l’édition en ligne et qui modifie les conditions de publication ou la politique éditoriale.
          </li>
        </ul>

        <p>
          Tout  manquement  de  l’Utilisateur  Pharmacien  à  ces  obligations  constituerait  une  contrefaçon  sanctionnée  par  les articles L. 335-2 et suivants du Code de la propriété intellectuelle.
        </p>

        <h3>11) Pharmacovigilance</h3>

        <p>
          Conformément aux obligations légales, réglementaires et déontologiques des Utilisateurs Pharmaciens, il incombe à ces derniers de déclarer tout effet indésirable suspecté d'être dû à un médicament ou produit mentionnés à l'article L. 5121-1 dont ils ont connaissance (Voir en particulier l’article L. 5121-25 Code de la santé publique)
        </p>

        <p>
          Tout signalement d’un événement sanitaire indésirable en ce compris les effets indésirables suspectés d'être dû à un médicament ou produit mentionnés à l'article L. 5121-1 peut être fait sur le site « signalement-sante.gouv.fr ».
        </p>

        <h3>12) Transparence</h3>

        <p>
          Conformément à l’article L. 1453-1 du Code de la santé publique, tel qu’il résulte de la loi n° 2011-2012 du 29 décembre 2011 relative au renforcement de la sécurité sanitaire des médicaments et des produits de santé, et aux articles D. 1453-1 et R. 1453-2 à R. 1453-9 du Code de la santé publique, OCTAPHARMA pourra rendre publique les informations liées à la mise à disposition du Service OCTAPRO à l’Utilisateur Pharmacien sur le site internet public visé à l’article R. 1453-4 du Code de la santé publique (www.transparence.sante.gouv.fr).
        </p>

        <p>
          Conformément à la loi du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés telle que modifiée, et le Règlement général sur la protection des données (RGPD), l’Utilisateur Pharmacien dispose de droits sur ses données à caractère personnel et notamment d’un droit d’accès et de rectification de ses données personnelles en contactant la personne responsable du site internet public sur lequel les informations sont publiées visé à l’article R. 1453-4 du Code  de  la  santé  publique  (www.transparence.sante.gouv.fr),  selon  les  procédures  prévues  par  le  site  internet concerné. L’obligation de transparence étant une obligation légale, l’Utilisateur Pharmacien ne peut pas s’opposer à la publication de ses données personnelles.
        </p>

        <h3>13) Assurance</h3>

        <p>
        OCTAPHARMA est assurée au titre de la Responsabilité Civile Professionnelle pour la fourniture et l’accès au Service OCTAPPRO.        </p>

        <p>
        Les  sous-traitants  et/ou  prestataires  et/ou  partenaires  de  OCTAPHARMA  font  leur  affaire  de  leurs  conditions d’assurance Responsabilité Civile Professionnelle pour la fourniture de prestations dans le cadre du Service, ce que l’Utilisateur Pharmacien reconnaît et accepte.
        </p>

        <p>
          L’Utilisateur Pharmacien est assuré au titre de sa responsabilité civile professionnelle.
        </p>

        <h3>14) Cas de force majeure</h3>

        <p>
        OCTAPHARMA ne sera pas tenue responsable en cas d’inaccessibilité de la Plateforme et/ou du Service causée par des évènements hors de son contrôle qui ne pouvaient être raisonnablement prévus et dont les effets ne peuvent être évités par des mesures appropriées, et empêchant l’exécution de ses obligations au sens de la définition légale figurant à l’article 1218 du Code civil (« Cas de Force Majeure »).
        </p>

        <p>
        L’exécution des CGU sera suspendue tant que durera le Cas de Force Majeure, sauf si le retard en résultant justifie la résiliation des présentes CGU, et OCTAPHARMA s’efforcera dans la mesure du possible de faire cesser le Cas de Force Majeure ou de trouver une solution lui permettant d'exécuter ses obligations contractuelles malgré le Cas de Force Majeure.
        </p>

        <p>
          Si l’empêchement est définitif, l’inscription de l’Utilisateur Pharmacien sera résiliée.
        </p>


        <h3>15) Modification des conditions générales d'utilisation et évolution du service</h3>

        <p>
          OCTAPHARMA se réserve le droit de modifier à tout moment les présentes CGU ou les règles concernant l’utilisation du Services OCTAPPRO.
        </p>

        <p>
        OCTAPHARMA se réserve en outre le droit de faire évoluer le Service. Des modifications techniques pourront intervenir sans préavis de la part de OCTAPHARMA.
        </p>

        <p>
          L’Utilisateur  Pharmacien  sera  informé  de  la  mise  en  ligne  au  sein  de  la  Plateforme  de  chaque  nouvelle  version  des présentes CGU et pourra être invité à les accepter à nouveau.
        </p>

        <p>
        En toutes hypothèses, le fait de continuer à utiliser le Service après toute modification des CGU entraîne l’acceptation des modifications des CGU.
        </p>

        <p>
          Enfin, OCTAPHARMA se réserve le droit de suspendre provisoirement ou définitivement l’accès au Service, sans délai, ni  contrepartie  de  quelque  nature  que  ce  soit  en  cas  de  force  majeure  ou  de  faits  techniques  ou  règlementaires indépendants de sa volonté.
        </p>

        <h3>16) Résiliation de l’inscription de l’Utilisateur Pharmacien au service</h3>

        <h4>16.1) A l’initiative de OCTAPHARMA </h4>

        <p>
        Chaque Utilisateur Pharmacien accepte que OCTAPHARMA résilie immédiatement son accès au Service sans préavis ni mise en demeure ni indemnité de quelque nature que ce soit en cas de manquement à l’une quelconque des obligations décrites aux présentes CGU, ou à la législation en vigueur. Sans limitation de ce qui précède, sont notamment susceptibles de fonder une résiliation de l’inscription de l’Utilisateur Pharmacien : 
        </p>

        <ul>
          <li>
            les infractions ou violations des présentes CGU ;
          </li>
          <li>
          le non-respect d’une disposition légale ou réglementaire en vigueur ;
          </li>
          <li>
          une tentative de connexion non autorisée, par une utilisation frauduleuse du système ou par une usurpation des éléments d’authentification ;
          </li>
          <li>
            un empêchement définitif ;
          </li>
          <li>
          l’arrêt du Service et/ou de la Plateforme par OCTAPHARMA ;
          </li>
          <li>
          en cas d’arrêt de marché avec OCTAPHARMA.
          </li>
        </ul>

        <h4>16.2) A l’initiative de l’utilisateur Pharmacien</h4>

        <p>
        À tout moment, l’Utilisateur Pharmacien a la possibilité de clôturer son Compte Personnel, en adressant une demande à l’adresse électronique suivante : fr2servicedesmarches@octapharma.com
        </p>

        <p>
        L’Utilisateur Pharmacien est informé que la fermeture de son Compte Personnel n’entraînera pas la suppression automatique des données à caractère personnel le concernant. Elles seront conservées, dans le respect des exigences posées par la Règlementation, ce qu’accepte expressément l’Utilisateur Pharmacien. 
        </p>

        <p>
          Son attention est également attirée sur le fait qu’à l’issue de cette action il ne sera plus en mesure d’accéder à la Plateforme et au Service OCTAPPRO. 
        </p>

        <h3>17) Cession</h3>

        <p>
          OCTAPHARMA ne peut céder les CGU à tout moment à une filiale ou à un successeur, quelle que soit l’opération.
        </p>

        <p>
          L’Utilisateur Pharmacien n’est pas autorisé à céder les droits dont il dispose en application des CGU à un tiers, sans l’autorisation expresse et préalable de OCTAPHARMA.
        </p>

        <h3>18) Confidentialité et sécurité</h3>

        <p>
          De manière générale, la préservation de la sécurité du Compte Personnel impose aux Utilisateurs Pharmaciens :
        </p>

        <ul>
          <li>
            de respecter les consignes de sécurité et notamment les règles relatives à la définition et au changement de ses éléments d’authentification ; 
          </li>
          <li>
            de respecter la gestion des accès, en particulier, ne pas utiliser les éléments d’authentification d'un autre Utilisateur Pharmacien, ni chercher à connaître ces informations ;
          </li>
          <li>
            de garder strictement confidentiels ses éléments d’authentification et ne pas les dévoiler à un tiers, quelles que soient ses qualités et activités professionnelles ; 
          </li>
          <li>
            d'avertir OCTAPHARMA de tout dysfonctionnement technique constaté et de toute anomalie découverte, telle que les intrusions.
          </li>
        </ul>

        <p>
          Notamment, il appartient à l’Utilisateur Pharmacien de prendre toutes les mesures appropriées de façon à protéger ses propres données et matériels de la contamination par des virus ou autres formes d’attaques circulant éventuellement via la Plateforme.
        </p>

        <p>
          Les Utilisateurs Pharmaciens sont informés que des interventions techniques sur le Service s’effectuent dans le respect des dispositions de la Règlementation et de l'ensemble des dispositions du Code de la santé publique.
        </p>

        <p>
          Les Utilisateurs Pharmaciens reconnaissent l’existence de risques inhérents à l'utilisation des télécommunications et ce, y compris même en présence d'un accès sécurisé tel qu'il est mis en œuvre dans le cadre du Service, et tout particulièrement en termes de :
        </p>

        <ul>
          <li>
            défaut de fiabilité du réseau Internet ;
          </li>
          <li>
            continuité non garantie dans l'accès à la Plateforme et à ses contenus et Services ;
          </li>
          <li>
            performances non garanties, compte tenu notamment de la propagation de virus ; 
          </li>
          <li>
            toutes autres contraintes techniques qui ne sont pas sous le contrôle et la responsabilité de OCTAPHARMA.
          </li>
        </ul>

        <p>
          En aucun cas, OCTAPHARMA ne saurait être tenue responsable de ces risques et de leurs conséquences préjudiciables, quelle qu’en soit l’étendue, pour l’Utilisateur Pharmacien.
        </p>


        <h3>19) Convention de preuve – Signature électronique</h3>

        <p>
          Les registres informatisés conservés dans les systèmes informatiques de OCTAPHARMA seront considérés comme les preuves des communications et des différentes transmissions d’écrits et de documents électroniques entre les Utilisateurs Pharmaciens et OCTAPHARMA.
        </p>

        <p>
          L’Utilisateur Pharmacien reconnaît et accepte que la preuve de l’acceptation des CGU est caractérisée par l’activation du clic (J’ai lu et j’accepte les conditions générales d’utilisation).
        </p>

        <p>
        L’Utilisateur Pharmacien reconnaît et accepte, qu’après son identification, toute manifestation de volonté par le biais de l’utilisation du Service, et en particulier l’acceptation des CGU, constitue une signature électronique au sens des dispositions des articles 1366 et suivants du Code civil, et manifeste son consentement en caractérisant sa preuve.
        </p>

        <p>
        Conformément  aux  dispositions  des  articles  1366  et  suivants  du  Code  civil,  la  mise  en  œuvre  d’une  signature électronique, sur la base d’un procédé fiable d'identification garantissant son lien avec l'acte auquel elle s'attache, est considérée comme une signature valable et comme une preuve au sens des dispositions précitées.
        </p>

        <p>
        L’Utilisateur Pharmacien ne pourra pas contester la recevabilité, la validité ou la force probante des éléments sous format ou support électronique précités, sur le fondement de quelque disposition légale que ce soit et qui spécifierait que certains documents doivent être écrits ou signés pour constituer une preuve. Ainsi, les éléments considérés constituent des preuves et, s’ils sont produits comme moyens de preuve par OCTAPHARMA dans toute procédure contentieuse ou autre, seront recevables, valables et opposables de la même manière, dans les mêmes conditions et avec la même force probante que tout document qui serait établi, reçu ou conservé par écrit. 
        </p>

        <p>
          À tout moment, l’Utilisateur Pharmacien a la faculté d’imprimer, de télécharger, et de conserver sur support papier et électronique un exemplaire des présentes CGU.
        </p>

        <h3>20) Loi applicable </h3>

        <p>
          Les CGU sont régies par la loi française et seront exécutées et interprétées conformément au droit français. 
        </p>

        <p>
          En cas de différend survenant entre les Parties au sujet de l’interprétation ou de l’exécution des CGU, et en dehors de cas de non-respect des délais de règlement, les Parties s’efforceront de trouver une solution amiable à leur différend dans les meilleurs délais. 
        </p>

        <p>
          A défaut de règlement amiable, tout litige en relation avec le Service sera soumis aux tribunaux français, quel que soit le lieu de résidence de l’Utilisateur Pharmacien et/ou le lieu duquel a été sélectionné le Service.
        </p>

        <div className={styles.end}>

        </div>

      </div>
    </div>
  )
}
