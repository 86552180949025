import React , { useRef , useMemo , useState , useEffect } from 'react'
import Month from './states/Month';
import History from './states/History';
import Stock from './states/Stock';

import { useGlobalContext } from '../../../../../../context/GlobalContext';
import './view.scss';
export default function View({ isOpen , drugs , clientId }) {

  const context = useGlobalContext()[0];

  const { view } = context.stock.filters;
  const contentRef = useRef(null)

  const [ viewIsOpen , setViewIsOpen ] = useState(false);

  useEffect(() => {
    if (contentRef.current.clientHeight && isOpen ) {
      setViewIsOpen(isOpen)
    }
    // eslint-disable-next-line
  }, [contentRef.current])

  useEffect(() => {
    if (contentRef.current.clientHeight) {
      setViewIsOpen(isOpen)
    }
    // eslint-disable-next-line
  }, [isOpen])

  return useMemo(() => (
      <div className={`view ${viewIsOpen ? " isActive" : ""}`} style={{ height: viewIsOpen && contentRef?.current?.clientHeight ? contentRef.current.clientHeight + 30 : 1 }}>
        <div ref={contentRef}>
          <Month
            drugs={drugs}
            viewIsActive={view === "month" ? true : false }/>
          <History
            drugs={drugs}
            clientId={clientId}
            viewIsActive={view === "history" ? true : false }/>
          <Stock
            drugs={drugs}
            clientId={clientId}
            viewIsActive={view === "stock" ? true : false }/>
        </div>
      </div>
        // eslint-disable-next-line 
  ),[view , viewIsOpen ])
}
