export const  //AUTH ACTIONS
              IS_AUTH =  'IS_AUTH',
              ERROR_AUTH = 'ERROR_AUTH',
              ERROR_LOGIN = 'ERROR_LOGIN',
              USER_AUTH = 'USER_AUTH',
              FORGOT_PASSWORD = 'FORGOT_PASSWORD',
              RESET_PASSWORD ='RESET_PASSWORD',
              GET_LIST= 'GET_LIST',
              LOGIN = 'LOGIN',
              PUT_USER_AUTH = 'PUT_USER_AUTH',
              MESSAGE_AUTH = 'MESSAGE_AUTH',
              SET_FILTERS = 'SET_FILTERS',
              //UPLOAD ACTIONS
              MESSAGE_UPLOAD = 'MESSAGE_UPLOAD',
              ERROR_UPLOAD = 'ERROR_UPLOAD',
              POST_STOCK = 'POST_STOCK',
              GET_STOCK = 'GET_STOCK',
              ERROR_STOCK = 'ERROR_STOCK',
              GET_PHARMACY_STOCK = 'GET_PHARMACY_STOCK',
              POST_EXPIRATIONS = 'POST_EXPIRATIONS';