import React , { useEffect , useCallback , useMemo , useState } from 'react';
import { useParams } from 'react-router-dom';

import { useGlobalContext } from '../../../context/GlobalContext';

import NavPharmacy from "../../PARTIALS/Nav/NavPharmacy";
import ProductRow from './Partials/ProductRow';

import { getPharmacyStockAction } from '../../../actions/stockActions';

import './pharmacy.scss'

export default function PharmacyExpiration() {

  const [ context, dispatch ] = useGlobalContext();

  const { pharmacyId } = useParams();

  const { stocksList,  filters } = context.stock;

  const [ productsFiltred , setProductsFiltred ] = useState();

  const getPharmacy = useCallback(()  => {
    getPharmacyStockAction(dispatch , pharmacyId )
  },[dispatch , pharmacyId]);

  useEffect(() => {
    getPharmacy();
  }, [getPharmacy]);

  useEffect(() => {
    if (stocksList) {
      setProductsFiltred(stocksList);
    }
  },[stocksList]);

  useEffect(() => {
    if (filters && filters.search) {
      let filtredSearch = stocksList.filter(item => {
        if (item.drug.name.toLowerCase().includes(filters.search.toLowerCase())){
          return item;
        } else {
          return null;
        }
      })
      setProductsFiltred(filtredSearch);
    } else {
      setProductsFiltred(stocksList);
    }
    // eslint-disable-next-line
  }, [filters]);


  return useMemo(() => {
    return (
      <>
        <NavPharmacy activePage={"expirations"}/>
        <div className="page-container pharmacy">
        {productsFiltred && productsFiltred.find(item => item.packs.length ? true :  false ) && 
          <div className="content product-list">
            <div className="product-row labels">
              <div className="main-col name">
                <p>Nom du médicament</p>
              </div>
            </div>
            {productsFiltred.filter(item => item?.packs.length ? true :  false ).map((product , i) => <ProductRow key={'peremption-row' + i } product={product} index={i} />)}
          </div>
        } 

        </div>


      </>
    // eslint-disable-next-line
  )},[productsFiltred , filters])
}

