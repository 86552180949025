import React from 'react';
import useDimensions from "react-use-dimensions";
import { BrowserRouter as Router } from 'react-router-dom'

import { GlobalProvider } from './context/GlobalContext';

import authReducer from './reducers/authReducer';
import stockReducer from './reducers/stockReducer';

import GlobalRouter from './components/GlobalRouter';
import PageMobile from './components/MOBILE/PageMobile';

function App() {

  const [ref, { width }] = useDimensions();

  function combineReducers(reducers) {
    const reducerKeys = Object.keys(reducers);
  
    return function combination(state = {}, action) {
      const nextState = {}
      for (let i = 0; i < reducerKeys.length; i++) {
        const key = reducerKeys[i];
        const reducer = reducers[key]
        const previousStateForKey = state[key]
        const nextStateForKey = reducer(previousStateForKey, action)
        nextState[key] = nextStateForKey;
      }
      return nextState;
    }
  }

  const reducers = combineReducers({
    auth: authReducer,
    stock: stockReducer
  });

  const initialState = reducers( {} , { type: '__INIT__' });

  return (
    <GlobalProvider
      reducers={reducers}
      initialState={initialState}
      >
      <div ref={ref} className="app-container">
        {width >= 1024 ?
          <Router>
            <GlobalRouter />
          </Router>
          :
          <PageMobile />
        }
      </div>
    </GlobalProvider>
  );
}

export default App;