import React , { useEffect , useState , useCallback } from 'react';
import { format } from 'date-fns';
import { BiLoader } from 'react-icons/bi';
import { MdCloudDownload } from 'react-icons/md'

import { useGlobalContext } from '../../../../context/GlobalContext';
import { postStockFileAction , postExpirationsFileAction } from '../../../../actions/stockActions';

import imageUpload from '../../../../assets/images/upload.svg';

import './Upload.scss'

export default function UploadPage({ setActivePage }) {

  const [ context , dispatch ] = useGlobalContext();

  const [ stockState , setStockState ] = useState();
  const [ expirationState , setExpirationState ] = useState();

  const { userAuth } = context.auth
  const { message , stockUpdatedAt , expirationUpdatedAt } = context.stock

  const isUploaded = useCallback(() => {
    dispatch({
      type: 'MESSAGE_UPLOAD',
      payload: null
    })

    dispatch({
      type: 'SET_TOAST',
      payload: {
        message: "Le fichier a été mis à jour",
        type: "success"
      }
    });
  }, [dispatch]);

  useEffect(() => {
    setActivePage("upload")
  }, [setActivePage]);

  useEffect(()=> {
    if (message === "stock file uploaded") {
      setStockState();
      isUploaded();
    } else if (message === "expiration file uploaded") {
      setExpirationState();
      isUploaded();
    }

  },[message , isUploaded])

  function handleChangeFile(type , file ) {
    switch (type) {
      case 'stock':
        if (file && file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
          return setStockState({ error : "le fichier n'est pas au format xlsx"} );
        } else {
          setStockState({ isLoading: true });
          const formData = new FormData();
          formData.append("file", file );
          return postStockFileAction(dispatch , formData )
        }

      case 'expiration':
        if (file && file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
          return setExpirationState({ error : "le fichier n'est pas au format xlsx"} );
        } else {
          setExpirationState({ isLoading: true });
          const formData = new FormData();
          formData.append("file", file );
          return postExpirationsFileAction(dispatch , formData )
        }
    
      default:
        break;
    }
  }

  return (
    <div className="page-container">
      <div className="content upload">

        <div className="col">

          {userAuth && <h1 >Bonjour <span className="text-primary">{userAuth.firstName} {userAuth.lastName}</span>,</h1>}
          <p>
            Bienvenue sur Octappro, <br/>Appli de suivi du contingentement et des péremptions
          </p>

          <div className="upload-row">

            <div className="info">
              <h2>Infos contingentement</h2>
              <p>Dernier upload : <span className="text-primary">{stockUpdatedAt && format(stockUpdatedAt , "dd/MM/yyyy' 'HH:mm")}</span></p>
              {stockState && stockState.isLoading ?
                <label className="btn primary">
                  <div className="loader"><BiLoader size={20} color={'#FFFFFF'} /></div>
                  Le fichier est en cours de traitement
                </label>
              :
                <label htmlFor="upload-stock" className="btn primary">
                  <MdCloudDownload size={20} color={'#FFFFFF'} />
                  Importer le fichier de contingentement
                </label>
              }
              <input type="file" name="upload-stock" onChange={(e) => handleChangeFile('stock' , e.target.files[0]) } id="upload-stock" />
              <p className="text-error">{stockState && stockState.error }</p>
            </div>

            <div className="info">
              <h2>Infos date de péremption</h2>
              <p>Dernier upload : <span className="text-primary">{expirationUpdatedAt && format(expirationUpdatedAt , "dd/MM/yyyy' 'HH:mm")}</span></p>
              {expirationState && expirationState.isLoading ?
                <label className="btn primary">
                  <div className="loader"><BiLoader size={20} color={'#FFFFFF'} /></div>
                  Le fichier est en cours de traitement
                </label>
              :
                <label htmlFor="upload-expiration" className="btn primary">
                  <MdCloudDownload size={20} color={'#FFFFFF'} />
                  Importer le fichier date de péremption
                </label>
              }
              <input type="file" name="upload-expiration" onChange={(e) => handleChangeFile('expiration' , e.target.files[0]) } id="upload-expiration" />
              <p className="text-error">{expirationState && expirationState.error }</p>
            </div>

          </div>

        </div>

        <div className="col-image">
          <img src={imageUpload} alt="medecin" />
        </div>

      </div>
    </div>
  )
}
