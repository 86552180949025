import React , { useState } from 'react'
import { useGlobalContext } from '../../../../context/GlobalContext';
import { format } from 'date-fns';

import { BiPlus , BiMinus } from 'react-icons/bi';
import commentIcon from '../../../../assets/images/icons/comment.svg';
import { colors } from '../../../../utils/constants';

export default function ProductRow({ product  , index }) {

  const context  = useGlobalContext()[0];

  const { view } = context.stock.filters;

  const [ commentsIsOpen , setCommentIsOpen ] = useState(false);
  const [ expirationIsOpen , setExpirationIsOpen ] = useState(index === 0 ? true: false);

  function dayDiff(peremptionDate) { 
    let oneDay = 86400000;
    let date = new Date(peremptionDate).getTime() / oneDay ;
    let today = new Date().getTime() / oneDay ;
    return (date - today).toFixed(0);
  }

  function renderProduct() {
    switch (view) {
      case "history":

        return (
          <div className="box product-row">
            <div className="main-col">
              <p className="bold name">{product.drug.name}</p>
            </div>
            {product.stockHistory  && product.stockHistory.map((item , i ) => {
              return (
                <div key={"history" + i } className="main-col history">
                  <p>{item.value}</p>
                </div>
              )
            })}
            <div className="comment-toggle">
              {product.history && product.history.Comment &&
                <button
                  onClick={() => setCommentIsOpen(!commentsIsOpen)}>
                  <img src={commentIcon} alt="comment" />
                </button>
              }
            </div>
          </div>
        )
            
      case "stock":

          return (
            <div className="box product-row">
              <div className="main-col">
                <p className="bold name">{product.drug.name}</p>
              </div>
              {product.plannedStock && product.plannedStock.map((item , i ) => {
                return (
                  <div key={"stock" + i } className="main-col stock">
                    <p>{item.value}</p>
                  </div>
                )
              })}
              <div className="comment-toggle">
                {product.history && product.history.Comment &&
                  <button
                    onClick={() => setCommentIsOpen(!commentsIsOpen)}>
                    <img src={commentIcon} alt="comment" />
                  </button>
                }
              </div>
            </div>
          )
    
      default:

        return (
          <div className="box product-row">
            <div className="main-col">
              <p className="bold name">{product.drug.name}</p>
            </div>
            <div className="main-col">
              <p>{product.stock}</p>
            </div>
            <div className="main-col">
              <p>{product.order}</p>
            </div>
            <div className="main-col">
              <p className={product.rest < 0 ? "text-error" : "text-primary"}>{product.rest}</p>
            </div>
            <div className="comment-toggle">
              {product.history && product.history['Comment Pharmacist'] &&
                <button
                  onClick={() => setCommentIsOpen(!commentsIsOpen)}>
                  <img src={commentIcon} alt="comment" />
                </button>
              }
            </div>
          </div>
        )
    }
  }

  function renderExpirationProduct() {
    return (
      <div className="box expiration">

        <div className="product-row">
          <div className="main-col">
            <p className="bold name">{product.drug.name}</p>
          </div>
          <div className="main-col">
            {product.packs.length > 1 && <span>{product.packs.length} lots</span>}
            {product.packs.length === 1 && <span>{product.packs.length} lot</span>}
          </div>
          <div className="main-col">
            <p></p>
          </div>
          <div className="main-col">
          </div>
          <button onClick={() => setExpirationIsOpen(!expirationIsOpen)}>
            {!expirationIsOpen ? <BiPlus size={10} color={colors.primary}/> : <BiMinus size={10} color={colors.primary}/>}
          </button>
        </div>

        <div className="list-lots" style={{ height: expirationIsOpen ? product.packs.length * 40 + 50 : 0 }}>
          {expirationIsOpen && product.packs.length > 0 &&
            <>
              <div className={`lot labels`}>
                <p className="number">Dosage</p>
                <p className="number">Quantité</p>
                <p className="number">Date</p>
                <p className="number">Commande</p>
                <p className="number">Lot</p>
                <p className="date">Péremption</p>
                <p className="number right">Jours restants</p>
              </div> 
              {product.packs.map((item , i ) => {
                return (
                  <div key={"lot" + i} className={`lot border`}>
                    <p className="number"><span className="bold">{item.dosage}</span></p>
                    <p className="number"><span className="bold">{item.quantity}</span></p>
                    <p className="number"><span className="bold">{format(new Date(item.date) , "dd/MM/yyyy")}</span></p>
                    <p className="number"><span className="bold">{item.invoice}</span></p>
                    <p className="number"><span className="bold">{item.number}</span></p>
                    <p className="date"><span className="bold">{item.expirationDate !== "Invalid Date" && format(new Date(item.expirationDate) , "dd/MM/yyyy")}</span></p>
                    <p className="number right"><span className={`bold ${dayDiff(item.expirationDate) < 0 ? "text-error" : "text-primary"}`}>{item.expirationDate !== "Invalid Date" && dayDiff(item.expirationDate)} jours</span></p>
                  </div>
                )
              })}
            </>
          }
        </div>

      </div>
    )
  }


  return (
    <>
      {product.history ? renderProduct() : renderExpirationProduct() }
      <div className={`comment${commentsIsOpen ? " isOpen" : "" }`}>
        <p>{commentsIsOpen && product.history && product.history['Comment Pharmacist']}</p>
      </div>      
    </>
  )
}
