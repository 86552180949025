import React , { useEffect , useCallback , useMemo , useState } from 'react';
import { useParams } from 'react-router-dom';
import { CgChevronDown , CgChevronUp } from 'react-icons/cg'

import { useGlobalContext } from '../../../context/GlobalContext';

import NavPharmacy from "../../PARTIALS/Nav/NavPharmacy";
import ProductRow from './Partials/ProductRow';

import { getPharmacyStockAction } from '../../../actions/stockActions';

import './pharmacy.scss'

export default function PharmacyStock() {

  const [ context, dispatch ] = useGlobalContext();

  const { pharmacyId } = useParams();

  const { stocksList,  filters } = context.stock;

  const initSortState = {
    name: true,
    stock: true,
    order: true,
    rest: true,
  }

  const [ productsFiltred , setProductsFiltred ] = useState();
  const [ renderList , setRenderList ] = useState(false);
  const [ sortByReverse , setSortByReverse] = useState(initSortState); 

  const getPharmacy = useCallback(()  => {
    getPharmacyStockAction(dispatch , pharmacyId );
  },[dispatch , pharmacyId]);

  useEffect(() => {
    getPharmacy();
  }, [getPharmacy]);

  useEffect(() => {
    if (stocksList) {
      setProductsFiltred(stocksList);
    }
  },[stocksList]);

  useEffect(() => {
    if (filters && filters.search) {
      let filtredSearch = stocksList.filter(item => {
        if (item.drug.name.toLowerCase().includes(filters.search.toLowerCase())){
          return item;
        } else {
          return null;
        }
      })
      setProductsFiltred(filtredSearch);
    } else {
      setProductsFiltred(stocksList);
    }
    // eslint-disable-next-line
  }, [filters]);

  function sortClients(type , isReverse ) {
    let list = productsFiltred;
    switch (type) {

      case 'name':
        list = list.sort((a , b ) => {
          if (a.drug.name < b.drug.name) return -1;
          if (a.drug.name > b.drug.name) return 1;
          return 0
        });
        setProductsFiltred(isReverse ? list.reverse() : list )
        setSortByReverse({...initSortState , name: isReverse })
        break;

      case 'stock':
        list = list.sort((a , b ) => {
          if (a.stock < b.stock) return -1;
          if (a.stock > b.stock) return 1;
          return 0
        });
        setProductsFiltred(isReverse ? list.reverse() : list )
        setSortByReverse({...initSortState , stock: isReverse })
        break;

      case 'order':
        list = list.sort((a , b ) => {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0
        });
        setProductsFiltred(isReverse ? list.reverse() : list )
        setSortByReverse({...initSortState , order: isReverse })
        break;
      
      case 'rest':
        list = list.sort((a , b ) => {
          if (a.rest < b.rest) return -1;
          if (a.rest > b.rest) return 1;
          return 0
        });
        setProductsFiltred(isReverse ? list.reverse() : list )
        setSortByReverse({...initSortState , rest: isReverse })
        break;
      
      
      default:
        break;
    }
    setRenderList(!renderList);
  }

  const Label = ({ name , action , state , className  }) => {
    return (
      <button onClick={() => action()} className={className}>
        <p>{name}</p> 
        <div className="icon">
          {state ?  <CgChevronUp size={23} color={'#999eb0'}/> : <CgChevronDown size={23} color={'#999eb0'}/>}
        </div> 
      </button>
    )
  }

  function renderLabelsByView(view) {
    var productFound = productsFiltred && productsFiltred.length > 0 && productsFiltred.find(item => item.history ? true :  false ) 
    switch (view) {
      case "stock":
          return (
            <>
              {productFound && productFound.plannedStock && productFound.plannedStock.map((item , i ) => {
                return (
                  <div key={"stock-label" + i} className="main-col stock">
                    <p>{item.month.split(" ")[0]} <br/>{item.month.split(" ")[1]}</p>
                  </div>
                )
              })}
            </>
          )

      case "history":
        return (
          <>
            {productFound && productFound.stockHistory && productFound.stockHistory.map((item , i )  => {
              return (
                <div key={"history-label" + i} className="main-col history">
                  <p>{item.month.split(" ")[0]} <br/>{item.month.split(" ")[1]}</p>
                </div>
              )
            })}
          </>
        )
    
      default:
          return (
            <>
            {sortByReverse &&
              <>
              <Label name={"Contingentement du mois"} action={() => sortClients('stock' , !sortByReverse.stock )} state={sortByReverse.stock} className={"main-col"}/>
              <Label name={"Commandé ce mois-ci"} action={() => sortClients('order' , !sortByReverse.order )} state={sortByReverse.order} className={"main-col"}/>
              <Label name={"Reste pour le mois en cours"} action={() => sortClients('rest' , !sortByReverse.rest )} state={sortByReverse.rest} className={"main-col"}/>
              </>
            }
            </>
          )
        
    }
  }

  return useMemo(() => {

    return (
      <>
        <NavPharmacy activePage={"stocks"}/>
        <div className="page-container pharmacy">
        {productsFiltred && productsFiltred.find(item => item.history ? true :  false ) &&
          <div className="content product-list">
            <div className="product-row labels">
              <Label name={"Nom du médicament"} action={() => sortClients('name' , !sortByReverse.name )} state={sortByReverse.name} className={"main-col name"}/>
              {renderLabelsByView(filters.view)}
            </div>
            {productsFiltred && productsFiltred.filter(item => item.history ? true :  false ).map((product , i) => <ProductRow key={'product-row' + i } product={product} index={i} />)}
          </div>
        }
        </div>

      </>
    // eslint-disable-next-line
  )},[productsFiltred , renderList , filters])
}

