import React from 'react';
import { months } from '../../../../../../../utils/constants';

export default function Stock({ viewIsActive , drugs , clientId }) {

   const Row = ({ drugStock , index  , type , count }) => {
     
     const { history , currentDate } = drugStock;

    var date = new Date(currentDate);

    var stock = [];
    
    for (const property in history) {
      let number = property.replace('Month ','')
      if (parseFloat(number) >= 0 && property !== 'Month 0') {
        let i = Math.abs(parseInt(number))
        let month = date.getMonth() + i;
        let label = <span>{months[month]}<br/>{date.getFullYear()}</span>
        if(month >= 12) {
          month = month - 12
          label = <span>{months[month]}<br/>{date.getFullYear() + 1 }</span>
        }
        stock.push({
          month: label,
          value: parseInt(history[property])
        });
      }
    }

    return (
      <>
        {type === "value" ?
          <div className={`list-row${count > 1 && index !== count-1 ? " border" : ""}`}>
            <p className="bold">{drugStock.drug.name} {drugStock.packs.length > 0 && <span className="lots">{drugStock.packs.length} lots</span>}</p>
            {stock.map((item , i) => <p key={'stock-value' + i} className="stock">{item.value}</p>)}
          </div>
          :
          <div className={"list-row labels"}>
            <p>Nom du médicament</p>
            {stock.map((item , i) => <p key={'stock-month' + i} className="stock border">{item.month}</p>)}
          </div>
        }
      </>
    )
   }

   return (
     <>
       {viewIsActive &&
         <> 
           <Row index={drugs.length + 1} drugStock={drugs[0]} type={"label"}/>
           {drugs.map((item , i) => <Row key={'item' + clientId + i} index={i} drugStock={item} type={"value"} count={drugs.length}/>)}
         </>
       }
     </>
   )
}
