import React , { useState , useEffect } from 'react';
import { BiPlus , BiMinus } from 'react-icons/bi';
import commentIcon from '../../../../../assets/images/icons/comment.svg'

import { colors } from '../../../../../utils/constants';
import Comments from './Comments/Comments';
import View from './View/View';

export default function ClientRow({ client , view , index , openList , setOpenList }) {

  const { profile , drugs } = client;
  const [ viewIsOpen , setViewIsOpen ] = useState(index === 0 ? true :false);
  const [ commentsIsOpen , setCommentIsOpen ] = useState(false);

  useEffect(() => {
    var list = openList.filter(i => i !== index);
    if (viewIsOpen && index !== 0) {
      list.push(index)
      setOpenList(list);
    } else {
      setOpenList(list);
    }
    // eslint-disable-next-line
  },[viewIsOpen]);

  useEffect(() => {
    if (!viewIsOpen && index === 0) {
      setViewIsOpen(true);
    }
    // eslint-disable-next-line
  },[index]);

  return (
    <>
      {client &&
        <>
          <div className="box">
            <div className="main-row">
              <div className="main-col structure">
                <p className="bold name">{client.profile && profile.structure && profile.structure}</p>
              </div>
              <div className="main-col">
                <p>{client.code}</p>
              </div>
              <div className="main-col">
                <p>{client.profile && client.profile.dept}</p>
              </div>
              <div className="main-col">
                <p>{client.profile && profile.city}</p>
              </div>
              <div className="main-col drugs">
                <p>{drugs && drugs.filter(item => item.history).length}</p>
              </div>
              <div className="main-col">
                <p>{client.totalComments}</p>
                {client.totalComments > 0 &&
                  <button
                    onClick={() => setCommentIsOpen(!commentsIsOpen)}
                    className="comment-toggle">
                    <img src={commentIcon} alt="comment" />
                  </button>
                }
              </div>
              <button
                onClick={() => setViewIsOpen(!viewIsOpen)}
                className={`view-toggle${index === 0 ? " disabled" : ""}`}>
                {!viewIsOpen ? <BiPlus size={38} color={colors.primary}/> : <BiMinus size={38} color={colors.primary}/>}
              </button>
            </div>
            <View
              isOpen={viewIsOpen}
              view={view}
              clientId={client._id}
              drugs={drugs.filter(item => item.history)}/>
          </div>
          <Comments
            clientId={client._id}
            isOpen={commentsIsOpen}
            comments={client.comments}/>
        </>
      }
    </>
  )
}
