import React from 'react';
import styles from './Logo.module.scss';

export default function Logo() {
  return (
    <div className={styles.logo}>
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.6867 1.31365C14.8402 0.466548 13.7139 0 12.5161 0C11.3182 0 10.1923 0.466548 9.34535 1.31365L5.32943 5.32943L1.31339 9.34574C0.466548 10.1928 0 11.3191 0 12.5166C0 13.7142 0.466548 14.8404 1.31339 15.687C2.15997 16.5341 3.2862 17.0006 4.48402 17.0006C5.68184 17.0006 6.80781 16.5341 7.65465 15.687L11.6707 11.6712L15.6867 7.65491C16.5336 6.80781 17 5.68158 17 4.48402C17 3.28646 16.5336 2.16023 15.6867 1.31365ZM6.80924 14.8416C6.18795 15.4627 5.36212 15.805 4.48402 15.805C3.60553 15.805 2.77996 15.4629 2.1588 14.8416C1.53765 14.2203 1.19575 13.3949 1.19575 12.5168C1.19575 11.6387 1.53791 10.8124 2.1588 10.1913L5.75214 6.59769L10.4026 11.2486L6.80924 14.8416ZM14.8413 6.80949L11.248 10.4031L6.59756 5.75214L10.1909 2.15906C10.812 1.53791 11.6379 1.19562 12.5161 1.19562C13.3945 1.19562 14.22 1.53778 14.8412 2.15906C15.4624 2.78035 15.8043 3.60579 15.8043 4.48389C15.8043 5.36199 15.4622 6.18821 14.8413 6.80949Z" fill="white"/>
        <path d="M14.1152 4.03086C13.9245 4.03086 13.7371 3.9398 13.6212 3.77106C13.3415 3.36236 12.88 3.1189 12.3863 3.1189C11.987 3.1189 11.6119 3.27416 11.3292 3.55679C11.0956 3.79026 10.7173 3.79026 10.4838 3.55679C10.2503 3.32319 10.2503 2.94484 10.4838 2.71137C10.9921 2.2028 11.6676 1.92316 12.3863 1.92316C13.2747 1.92316 14.1052 2.36104 14.6079 3.09504C14.7944 3.36755 14.725 3.73954 14.4524 3.92645C14.3493 3.997 14.2316 4.03086 14.1152 4.03086Z" fill="white"/>
      </svg>
    </div>
  )
}
