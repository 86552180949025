import React, { useEffect , useCallback } from "react";
import { Switch , Route , useHistory } from "react-router-dom";

import { useGlobalContext } from '../context/GlobalContext';
import { getUserAuthAction } from '../actions/authActions';

import { setVhUnit } from '../utils/utils';
import { cguDate } from '../utils/constants';

import Login from './AUTH/Login';
import ResetPassword from './AUTH/ResetPassword';
import AdminRouter from './ADMIN/AdminRouter';

import Toaster from './PARTIALS/Toaster/Toaster';
import PharmacyStock from "./MEMBERS/Pharmacy/PharmacyStock";
import PharmacyExpiration from './MEMBERS/Pharmacy/PharmacyExpiration';

import DataPolicy from "./LEGAL/DataPolicy";
import Legal from "./LEGAL/Legal";
import Transparency from "./LEGAL/Transparency";
import Cgu from "./LEGAL/Cgu";
import ValidCgu from "./AUTH/ValidCgu";
import { TOOL_ID } from "../actions";

export default function GlobalRouter() {

  const [ context , dispatch] = useGlobalContext();

  const history = useHistory();

  const getData = useCallback(() => {
    getUserAuthAction(dispatch);
  },[dispatch]);

  useEffect(() => {
    setVhUnit();
    getData();
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(context.auth.error === 401) {
      logout();
    }
  // eslint-disable-next-line 
  }, [context.auth.error]);

  useEffect(() => {

    if(context.auth.userAuth) {
      const { _id }  = context.auth.userAuth;
      console.log(context.auth.userAuth)
      var octasmartProduct = context.auth.userAuth.tools.find(item => item.tool === TOOL_ID );
      const validDate = octasmartProduct?.cguAcceptanceDate ? new Date(octasmartProduct.cguAcceptanceDate) : null;
      if (validDate && validDate > new Date(cguDate)) {
        if (octasmartProduct.role === "Admin") return history.push("/admin");
        if (octasmartProduct.role === "Member") return history.push(`/pharmacy/stocks/${_id}`);
      } else {
        return history.push("/valid-cgu/");
      }
    }
  // eslint-disable-next-line 
  }, [context.auth.userAuth]);

  function logout() {
    history.push("/");
    localStorage.removeItem('token');
    dispatch({
      type : 'IS_AUTH',
      payload : false
    });
    dispatch({
      type : 'USER_AUTH',
      payload : null
    });
  };

  return (
    <>
      <Switch>
        
        <Route exact path="/" render={
          () => <Login
            />
        }/>

        <Route exact path="/reset-password/:token" render={
          () => <ResetPassword
            />
        }/>

        <Route path="/pharmacy/stocks/:pharmacyId" render={
          () => <PharmacyStock />
        }/>

        <Route path="/pharmacy/expirations/:pharmacyId" render={
          () => <PharmacyExpiration />
        }/>


        <Route path="/admin">
          <AdminRouter />
        </Route>

        <Route exact path="/legal" render={
          (props) => <Legal
          {...props}
          />
        }/>

        <Route exact path="/data-policy" render={
          (props) => <DataPolicy
          {...props}
          />
        }/>

        <Route exact path="/transparency" render={
          (props) => <Transparency
          {...props}
          />
        }/>

        <Route exact path="/cgu" render={
          (props) => <Cgu
          {...props}
          />
        }/>

        <Route exact path="/valid-cgu" render={
          (props) => <ValidCgu
          {...props}
          />
        }/>

      </Switch>
      <Toaster toast={context.auth.toast} dispatch={dispatch}/>
    </>
  )
}
