import React, { useState , useEffect } from 'react';
import { BsFilterLeft } from "react-icons/bs";
import { RiArrowLeftSLine } from "react-icons/ri";
import { BiSearch } from "react-icons/bi";
import { MdRadioButtonChecked , MdRadioButtonUnchecked } from "react-icons/md";

import './filters.scss';
import { useGlobalContext } from '../../../context/GlobalContext';

export default function Filters({ isDisabled }) {

  const [ context, dispatch ] = useGlobalContext();

  const [ filterIsOpen , setFiltersIsOpen ] = useState(false);
  const [ search , setSearch ] = useState();
  const [ filters , setFilters ] = useState(context.stock.filters);

  useEffect(() => {
    if (isDisabled) {
      setFiltersIsOpen(false);
    }
  }, [isDisabled]);

  useEffect(() => {
    dispatch({
      type: 'SET_FILTERS',
      payload: filters
    });
    // eslint-disable-next-line 
  },[filters]);

  function getClassNames() {
    let classNames = 'filters'
    if (isDisabled) {
      classNames += ' disabled';
      return classNames
    }
    if (filterIsOpen) {
      classNames += ' isOpen';
    }
    return classNames
  }

  return (
    <div className={getClassNames()}>
      <button className="btn-filters" onClick={() => setFiltersIsOpen(!filterIsOpen)}>
      {filterIsOpen? <RiArrowLeftSLine size={28} color={"#FFFFFF"}/>  : <BsFilterLeft size={30} color={"#FFFFFF"}/> }
      </button>
      {filterIsOpen &&
        <>

          <div className={`filter ${filterIsOpen ? "isOpen border" : ""}`}>
            <input
              value={search ? search :  "" }
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Rechercher"
              />
            <button
              className="btn-search"
              onClick={() => setFilters({...filters , search: search ? search : "" })}>
              <BiSearch size={18} color={"#FFFFFF"}/>
            </button>
          </div>

          <div className={`filter ${filterIsOpen ? "isOpen" : ""}`}>
            <button
              onClick={() => setFilters({...filters , view: "month" })}
              className={`btn-radio${filters.view === "month" ? " isActive" : ""}`}>
              {filters.view === "month" ? <MdRadioButtonChecked size={14} color={"#FFFFFF"}/> : <MdRadioButtonUnchecked size={14} color={"#FFFFFF"}/> }
              <p>Mois en cours</p> 
            </button>
          </div>

          <div className={`filter ${filterIsOpen ? "isOpen" : ""}`}>
            <button
              onClick={() => setFilters({...filters , view: "history" })}
              className={`btn-radio${filters.view === "history" ? " isActive" : ""}`}>
              {filters.view === "history" ? <MdRadioButtonChecked size={14} color={"#FFFFFF"}/> : <MdRadioButtonUnchecked size={14} color={"#FFFFFF"}/> }
              <p>Historique</p> 
            </button>
          </div>

          <div className={`filter ${filterIsOpen ? "isOpen" : ""}`}>
            <button
              onClick={() => setFilters({...filters , view: "stock" })}
              className={`btn-radio${filters.view === "stock" ? " isActive" : ""}`}>
              {filters.view === "stock" ? <MdRadioButtonChecked size={14} color={"#FFFFFF"}/> : <MdRadioButtonUnchecked size={14} color={"#FFFFFF"}/> }
              <p>Contingentement</p> 
            </button>
          </div>
        </>
      }
    </div>
  )
}
