import {
  ERROR_UPLOAD,
  POST_STOCK,
  MESSAGE_UPLOAD,
  ERROR_STOCK,
  GET_STOCK,
  SET_FILTERS,
  GET_PHARMACY_STOCK,
  POST_EXPIRATIONS
} from "../actions/types";

const initialState = {
  error: null,
  message: null,
  stocksList: null,
  stockUpdatedAt : null ,
  expirationUpdatedAt : null ,
  pharmacist: null,
  expirationsList: null,
  filters: { view: "month"}
};

export default function stockReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FILTERS:
      return {...state , filters: action.payload }
    case GET_PHARMACY_STOCK: 
      return {...state , stocksList: action.payload.stocksList , pharmacist: action.payload.pharmacist }
    case GET_STOCK:
      return {...state ,
        stocksList: action.payload.stocksList ,
        expirationsList: action.payload.expirationsList , 
        stockUpdatedAt: new Date(action.payload.stockUpdatedAt),
        expirationUpdatedAt: new Date(action.payload.expirationUpdatedAt)
      }
    case MESSAGE_UPLOAD:
      return {...state , message: action.payload }
    case POST_EXPIRATIONS:
      return {...state , message: action.payload , expirationUpdatedAt: new Date() }
    case POST_STOCK:
      return {...state , message: action.payload , stockUpdatedAt: new Date() }
    case ERROR_STOCK:
      return {...state , error: action.payload}
    case ERROR_UPLOAD:
      return {...state , error: action.payload}
    default:
      return {...state};
  }
}